import * as React from 'react'
import { useForm } from 'react-hook-form'
import { LesionLabel } from '../../../../types'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { applyLesionLabelAPI } from '../../../../reducers/func/dataManagementFunc'

interface IEditLesionLabel {
    lesionlabel: LesionLabel
    onEdit: (lesionlabel: LesionLabel) => void
}

export const EditLesionLabel: React.FC<IEditLesionLabel> = (props: IEditLesionLabel) => {
    // const [selId, setSelId] = React.useState<string | null>(null)
    // const [selOrgan, setSelOrgan] = React.useState<string | null>(null)
    // const [sellabel, setSelLabel] = React.useState<number>(0)
    // const [sellesiontype, setSelLesionType] = React.useState<number>(0)
    const [symptoms, setSymptoms] = React.useState<string>('')
    const [edited, setEdited] = React.useState<boolean>(false)
    const { lesionlabel, onEdit } = props
    const form = useForm()

    const setText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSymptoms(event.target.value)
    }

    const handleLesionLabelChange = (event: SelectChangeEvent) => {
        const label = parseInt(event.target.value)
        // setSelLabel(label)
        setEdited(true)
        const newlesionlabel: LesionLabel = {
            ...lesionlabel,
            label: label,
            edited: true
        }
        onEdit(newlesionlabel)
    }

    const handleLesionTypeChange = (event: SelectChangeEvent) => {
        const lesion_type = parseInt(event.target.value)
        // setSelLesionType(lesion_type)
        setEdited(true)
        const newlesionlabel: LesionLabel = {
            ...lesionlabel,
            lesion_type: lesion_type,
            edited: true
        }
        onEdit(newlesionlabel)
    }

    const handleSymptomsChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const newsymptoms = event.target.value
        // if (symptoms !== newsymptoms) {
        //     setSymptoms(newsymptoms)
        setEdited(true)
        const newlesionlabel: LesionLabel = {
            ...lesionlabel,
            symptoms: newsymptoms,
            edited: true
        }
        onEdit(newlesionlabel)
        // }
    }

    const handleApplyChange = () => {
        applyLesionLabelAPI(lesionlabel.id as string, lesionlabel.label, lesionlabel.lesion_type, lesionlabel.symptoms)
            .then((response) => {
                setEdited(false)
                console.log("Apply change success: ", response)
                onEdit({ ...lesionlabel, edited: false })
            })
            .catch((error) => {
                console.log("Error applying change: ", error)
            })
    }

    React.useEffect(() => {
        if (lesionlabel !== null && lesionlabel !== undefined) {
            // setSelId(lesionlabel.id?.toString() ?? '')
            // setSelOrgan(lesionlabel.organ_label.labelname)
            // setSelLabel(lesionlabel.label)
            // setSelLesionType(lesionlabel.lesion_type)
            setSymptoms(lesionlabel.symptoms)
            if (lesionlabel.edited !== undefined && lesionlabel.edited !== null) {
                setEdited(lesionlabel.edited)
            } else {
                setEdited(false)
            }

        }
    }, [lesionlabel])

    return (
        <form>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                <Grid item xs={6}>
                    <Grid container direction='column' spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <Box>
                                    <Typography variant='body2'>Id: {(lesionlabel !== undefined && lesionlabel !== null) ? lesionlabel.id : ''} </Typography>
                                </Box>
                                <Box>
                                    <Typography variant='body2'>Organ: {(lesionlabel !== undefined && lesionlabel !== null) ? lesionlabel.organ_label.labelname : ''}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <FormControl variant="standard">
                                    <InputLabel id="lesionlabel-select-standard-label">Lesion Label</InputLabel>
                                    <Select
                                        labelId="lesionlabel-select-standard-label"
                                        id="lesionlabel-select-standard"
                                        value={(lesionlabel !== undefined ? lesionlabel.label : 0) as unknown as string}
                                        label="lesionlabel"
                                        color="primary"
                                        sx={{ width: "107px" }}
                                        onChange={handleLesionLabelChange}
                                    >
                                        <MenuItem value={0}>Normal</MenuItem>
                                        <MenuItem value={1}>Abnormal</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard">
                                    <InputLabel id="lesiontype-select-standard-label">Lesion Label</InputLabel>
                                    <Select
                                        labelId="lesiontype-select-standard-label"
                                        id="lesiontype-select-standard"
                                        value={(lesionlabel !== undefined ? lesionlabel.lesion_type : 0) as unknown as string}
                                        label="lesiontype"
                                        disabled={lesionlabel !== undefined && lesionlabel.label === 0}
                                        color="primary"
                                        sx={{ width: "107px" }}
                                        onChange={handleLesionTypeChange}
                                    >
                                        <MenuItem value={0}>Other</MenuItem>
                                        <MenuItem value={1}>Bleeding</MenuItem>
                                        <MenuItem value={2}>Ulcer</MenuItem>
                                        <MenuItem value={3}>Erosion</MenuItem>
                                        <MenuItem value={4}>Inflammation</MenuItem>
                                        <MenuItem value={5}>Angioectasia</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' spacing={1} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                        <TextField
                            id="filled-multiline-static"
                            label="Notes:"
                            multiline
                            rows={4}
                            maxRows={4}
                            sx={{ width: "200px", mt: 2 }}
                            defaultValue={(lesionlabel !== undefined && lesionlabel !== null && lesionlabel.symptoms !== null) ? lesionlabel.symptoms : ''}
                            value={symptoms !== null ? symptoms : ''}
                            variant="standard"
                            onChange={setText}
                            onBlur={handleSymptomsChange}
                        />
                        <Button
                            variant='contained'
                            disabled={!edited}
                            onClick={handleApplyChange}
                        >
                            Apply change
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}