import * as React from "react"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemIcon, ListItemText, Modal, Paper, Popover, Radio, RadioGroup, Stack, Toolbar, Typography } from "@mui/material";
import { DataSrcMenuPopOver } from "../popovers/DataSrcMenuPopOver";
import { CapsuleEndoscopyTaskContext, CapsuleEndoscopyTaskRefs } from "../../../../providers/CapsuleEndoscopyTaskContext";
import Backdrop from '@mui/material/Backdrop'
import { DatasetList } from "../lists/DatasetList";

interface IDataSrcToggler {
    onDataSrcSelected: (t: string, fl: File[] | null) => void 
}
export const DataSrcToggler: React.FC<IDataSrcToggler> = (props: IDataSrcToggler) => {
    const [datasrcPopOverAnchorEl, setDataSrcPopOverAnchorEl] = React.useState<HTMLElement | null>(null)
    const [opendatalistModal, setOpenDataListModal] = React.useState(false)
    const [availabledatalist, setAvailableDataList] = React.useState<File[]>([])
    const [seldatasrc, setSelDataSrc] = React.useState<boolean[] | null>(null)
    const { onDataSrcSelected } = props
    
    const handleDataSrcPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setDataSrcPopOverAnchorEl(event.currentTarget);
    }

    const handleDataSrcPopoverClose = () => {
        setDataSrcPopOverAnchorEl(null);
    }

    const openDataSrcPopOver = Boolean(datasrcPopOverAnchorEl)

    const handleDeviceFileSelected = (fl: FileList) => {
        const f_arr = Array.from(fl)
        let datalist: File[] = []
        let sellist: boolean[] = []
        f_arr.forEach((f: File) => {
            // if (f.name.split('.').pop() == 'gvf') {
            datalist.push(f)
            // }
            sellist.push(true)
        })
        if (datalist.length > 0) {
            setSelDataSrc(sellist)
        }
        setAvailableDataList(datalist)
        setOpenDataListModal(true)
    }

    const handleDeviceDirSelected = (fl: FileList) => {
        const f_arr = Array.from(fl)
        let datalist: File[] = []
        let sellist: boolean[] = []
        f_arr.forEach((f: File) => {
            // if (f.name.split('.').pop() == 'gvf') {
            datalist.push(f)
            // }
            sellist.push(true)
        })
        if (datalist.length > 0) {
            setSelDataSrc(sellist)
        }
        setAvailableDataList(datalist)
        setOpenDataListModal(true)
    }

    const handleDeviceDataListChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        // availabledatalist.forEach((f: File) => {
        //     if (f.name === (event.target as HTMLInputElement).value) {
        //         setSelDataSrc(f)
        //     }
        // })
        const newlist: boolean[] | undefined = seldatasrc?.map((selected: boolean, i: number) => i==index ? event.target.checked:selected)
        if (newlist) setSelDataSrc(newlist)
    }

    const handleDataSrcFileConfirmed = () => {
        setOpenDataListModal(false)
        setDataSrcPopOverAnchorEl(null)
        let seldata: File[] = []
        if (seldatasrc) {
        for (var i = 0; i < availabledatalist.length; i++) {
            if (seldatasrc[i]) {
                seldata.push(availabledatalist[i])
            }
        }
        }
        onDataSrcSelected('device-src', seldata)
    }

    const handleOpenDataListModal = () => setOpenDataListModal(true)
    const handleCloseDataListModal = () => setOpenDataListModal(false)

    return (
        <>
        <Button
            variant='contained'
            endIcon={<ArrowDropDownIcon />}
            onClick={handleDataSrcPopoverOpen}
            
        >
            Browse Data
        </Button>
        <Popover
            id="filesrc-popover"
            open={openDataSrcPopOver}
            anchorEl={datasrcPopOverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            marginThreshold={60}
            onClose={handleDataSrcPopoverClose}
            disableRestoreFocus
            PaperProps={{
                elevation: 2,
                style: {
                width: '220px'
                }
            }}
        >
            <DataSrcMenuPopOver 
                onDeviceFileSelected={handleDeviceFileSelected}
                onDeviceDirSelected={handleDeviceDirSelected} />
        </Popover>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={opendatalistModal}
            onClose={handleCloseDataListModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            >
        <Fade in={opendatalistModal}>
        <Paper
            square={true}
            elevation={0}
            sx = {{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '400px',
                height: '212px',
                padding: 0
            }}
        >
            <Stack spacing={0}>
                <Toolbar
                    variant='dense'
                    sx={{
                        minHeight: '38px',
                        borderBottom: '2px solid #212121'
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography id="datalist-modal-title" variant='subtitle1'>Available data sources</Typography>
                    </Box>
                    
                </Toolbar>
                <Box
                    sx={{
                        display: 'flex',
                        height: '100px',
                        p: 1,
                        overflow: 'auto'
                    }}
                >
                {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={availabledatalist.length > 0 ? availabledatalist[0].name:null}
                    name="radio-buttons-group"
                    value={seldatasrc?.name}
                    onChange={handleDeviceDataSrcChange}
                >
                    {availabledatalist.map((f: File) => (
                        <FormControlLabel value={f.name} control={<Radio />} label={f.name} />
                    ))}
                </RadioGroup> */}
                {/* <FormGroup> */}
                <List disablePadding
                        sx={{
                            width: '100vw'
                        }}
                    >
                {seldatasrc && availabledatalist.map((f: File, i: number) => (
                    // <FormControlLabel value={f.name} control={<Checkbox checked={seldatasrc[i]} />} label={f.name} />
                    
                        <ListItem>
                            <ListItemIcon sx={{ minWidth: 32 }}>
                                <Checkbox
                                edge="start"
                                checked={seldatasrc[i]}
                                disableRipple
                                onChange={(e) => handleDeviceDataListChange(e, i)}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item xs={8}>
                                                <Typography variant='caption'>
                                                {f.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='caption'>
                                                {f.size} bytes
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </React.Fragment>
                                    
                                }
                            />
                        </ListItem>
                    
                ))}
                </List>
                {/* </FormGroup> */}

                </Box>
                <Box
                    justifyContent='flex-end'
                    sx={{
                        display: 'flex',
                        minHeight: '38px',
                        pt: 1,
                        pl: 2,
                        pr: 2,
                        borderTop: '1px solid #212121'
                    }}
                >
                    <Button
                        variant='contained'
                        onClick={handleDataSrcFileConfirmed}
                    >
                        Confirm Selection
                    </Button>
                </Box>
            </Stack>
        </Paper>
        </Fade>
        </Modal>
        </>
    )
}