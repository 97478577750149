import React from "react"
import { useAppSelector } from "../../../../hooks/AppReducerHooks"
import { toAbsoluteUrl } from "../../../../helpers"
import { Link } from "react-router-dom"
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material"
import { stringAvatar, stringToColor } from "../AvatarUtils"
import PersonIcon from '@mui/icons-material/Person'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'


export const UserMenuPopOver: React.FC = () => {
    const userInfo = useAppSelector((state) => state.user)
    const now = (new Date()).getTime() / 1000
    return (
      <Card sx={{ width: '400px' }}>
        <CardMedia
            component="img"
            height="140"
            image={toAbsoluteUrl(
                "/media/misc/bluri-dark-bg.jpg"
              )}
            alt="bluri dark"
        />
        <CardContent sx={{ paddingTop: '5px', paddingLeft: '5px', paddingRight: '5px' }}>
            
            {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
                
                    <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                        <Box sx={{ position:"relative" }}>
                          {userInfo.profile?.avatar ? (
                            <Avatar 
                            alt={userInfo.profile?.user?.name as string} 
                            src={userInfo.profile?.avatar} 
                            sx={{ width: 72, height: 72, position:"absolute", top: -56 }}
                            />
                          ):(
                            <Avatar {...stringAvatar(userInfo.profile?.user?.name as string)}
                            sx={{ fontSize: 36, bgcolor: stringToColor(userInfo.profile?.user?.name as string), width: 72, height: 72, position:"absolute", top: -56 }}
                            />
                          )}
                        
                        </Box>
                        </Grid>

                    <Grid item xs={9}>
                        
                        <Typography gutterBottom variant="h5" component="div">
                        {userInfo.profile?.user?.name}
                        </Typography>
                        
                    </Grid>
                    </Grid>
               
            ):(
                <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                <Avatar>
                <PersonIcon />
                </Avatar>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h5" component="div">
                    You're not signed in.
                    </Typography>
                </Grid>
                </Grid>
            )}
            {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
                <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '10px' }}>
                <ListItem key='account-settings' disablePadding>
                <ListItemButton>
                    <ListItemAvatar>
                    <Avatar>
                        <ManageAccountsIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Manage Account" secondary="Edit your account detail" />
                </ListItemButton>
                </ListItem>
                </List>
            ):(
                <div style={{paddingTop: '10px'}}>
                <Typography variant="body2" color="text.secondary">
                Sign In or Sign Up to access all PixarTee's features.
                </Typography>
                </div>
            )}
        </CardContent>
        <CardActions>
        {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
            <>
            <Button 
                color="primary"
                variant="contained"
                sx={{
                    width: 400
                }} 
            >
              
              Sign Out
              
                
                
            </Button>
            </>
        ):(
            <>
            <Button size="small">
                <Link to="/signin">
                Sign In
                </Link>
            </Button>
            <Button size="small">
                <Link to="/signup">
                Sign Up
                </Link>
            </Button>
            </>
        )}
        </CardActions>
  </Card>
    )
}