import * as React from "react"
import { useAppSelector } from "../../../../hooks/AppReducerHooks"
import { toAbsoluteUrl } from "../../../../helpers"
import IconButton from '@mui/material/IconButton'
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CollectionsIcon from '@mui/icons-material/Collections';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Avatar, Popover } from "@mui/material"
import { stringAvatar } from "../AvatarUtils"
import { UserMenuPopOver } from "../popovers/UserMenuPopOver";


export const UserToggler: React.FC = () => {
    const userInfo = useAppSelector((state) => state.user)
    const [userInfoPopoverAnchorEl, setUserInfoPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
    const now = (new Date()).getTime() / 1000
    const handleUserInfoPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserInfoPopoverAnchorEl(event.currentTarget);
    };

    const handleUserInfoPopoverClose = () => {
        setUserInfoPopoverAnchorEl(null);
    };

    const openUserInfoPopover = Boolean(userInfoPopoverAnchorEl)
      
    return (
        <>
        <IconButton 
            aria-owns={openUserInfoPopover ? 'userinfo-popover' : undefined}
            aria-haspopup="true"
            onClick={handleUserInfoPopoverOpen}
            sx={{
                width: '55px',
                height: '55px'
            }}
            >
        {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
            userInfo.profile?.avatar ? (
                <Avatar alt={userInfo.profile?.user?.name as string} src={userInfo.profile?.avatar} 
                sx={{
                    width: '48px',
                    height: '48px'
                }} />
            ):(
                <Avatar {...stringAvatar(userInfo.profile?.user?.name as string)} />
            )
        ):(
            <Avatar>
            <PersonIcon />
            </Avatar>
        )}
        </IconButton>
        <Popover
            id="userinfo-popover"
            open={openUserInfoPopover}
            anchorEl={userInfoPopoverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={60}
            onClose={handleUserInfoPopoverClose}
            disableRestoreFocus
            PaperProps={{
                elevation: 2,
                style: {
                width: '400px'
                }
            }}
        >
            <UserMenuPopOver />
        </Popover>
        
        
        </>
    )

}