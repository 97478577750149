import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material'
import * as React from 'react'
import { classifyAndDetectAPI, fetchDatasetsAPI, fetchModelsAPI, giorganClassifyAPI } from '../../../../reducers/func/dataManagementFunc'
import { Dataset, MLModel } from '../../../../types'
import { SelectChangeEvent } from '@mui/material/Select';

interface INewGIOrgan {
    task?: string
    onSuccess: () => void
}

export const NewGIOrganForm: React.FC<INewGIOrgan> = (props: INewGIOrgan) => {
    const [datasets, setDatasets] = React.useState<Dataset[] | null>(null)
    const [selds, setSelDS] = React.useState<string | null>(null)
    const [organmodels, setOrganModels] = React.useState<MLModel[] | null>(null)
    const [selorganmodel, setSelOrganModel] = React.useState<string>('0')
    const [lesionmodels, setLesionModels] = React.useState<MLModel[] | null>(null)
    const [sellesionmodel, setSelLesionModel] = React.useState<string>('0')
    const [applyLesionDetection, setApplyLesionDetection] = React.useState<boolean>(false)
    const { task, onSuccess } = props

    const handleDatasetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelDS((event.target as HTMLInputElement).value as string);
    }

    const handleOrganModelChange = (event: SelectChangeEvent) => {
        setSelOrganModel(event.target.value)
    }

    const handleLesionModelChange = (event: SelectChangeEvent) => {
        setSelLesionModel(event.target.value)
    }

    const handleChangeApplyLesionDetection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplyLesionDetection((event.target as HTMLInputElement).checked)
    }

    const handleSubmit = () => {
        if (applyLesionDetection) {
            classifyAndDetectAPI(task as string, selds as string, selorganmodel as string, sellesionmodel as string)
                .then((response) => {
                    onSuccess()
                })
                .catch((error) => {
                    console.log("Error creating organ classification and lesion detection task: ", error)
                })
        } else {
            giorganClassifyAPI(task as string, selds as string, selorganmodel as string)
                .then((response) => {
                    onSuccess()
                })
                .catch((error) => {
                    console.log("Error creating organ classification task: ", error)
                })
        }
    }

    React.useEffect(() => {
        if (task && organmodels == null) {
            // fetch organ models
            fetchModelsAPI('0')
                .then((response) => {
                    setOrganModels(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching models: ", error)
                })
        }
        if (task && lesionmodels == null) {
            // fetch lesion models
            fetchModelsAPI('1')
                .then((response) => {
                    setLesionModels(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching models: ", error)
                })
        }
        if (task && datasets == null) {
            // fetch files list
            fetchDatasetsAPI(task)
                .then((response) => {
                    setDatasets(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching datasets: ", error)
                })
        }
    }, [])

    return (
        <>
            <Stack spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        height: '180px',
                        overflow: 'auto'
                    }}
                >
                    <FormControl>
                        <FormLabel id="dsselect-rgroup-label">Select dataset to be classified</FormLabel>
                        <RadioGroup
                            aria-labelledby="dsselect-rgroup-label"
                            name="dsselect-rgroup"
                            onChange={handleDatasetChange}
                            sx={{
                                pl: 2
                            }}
                        >
                            {datasets && datasets.map((ds: Dataset) => (
                                <FormControlLabel value={ds.id} control={<Radio />} label={ds.dataset_uid} />
                            ))}


                        </RadioGroup>
                    </FormControl>
                    <Stack spacing={2}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="organmodel-select-standard-label">Organ Classification Model</InputLabel>
                            <Select
                                labelId="organmodel-select-standard-label"
                                id="organmodel-select-standard"
                                value={selorganmodel}
                                onChange={handleOrganModelChange}
                                label="organmodel"
                            >
                                {organmodels && organmodels.map((mm: MLModel) => (
                                    <MenuItem value={mm.id}>{mm.model_architecture}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormControlLabel control={<Checkbox checked={applyLesionDetection} onChange={handleChangeApplyLesionDetection} />} label="Apply lesion detection" />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="lesionmodel-select-standard-label">Lesion Detection Model</InputLabel>
                            <Select
                                labelId="lesionmodel-select-standard-label"
                                id="lesionmodel-select-standard"
                                value={sellesionmodel}
                                onChange={handleLesionModelChange}
                                label="lesionmodel"
                                disabled={!applyLesionDetection}
                            >
                                {lesionmodels && lesionmodels.map((mm: MLModel) => (
                                    <MenuItem value={mm.id}>{mm.model_architecture}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
                <Box
                    justifyContent='flex-end'
                    sx={{
                        display: 'flex',
                        minHeight: '38px',
                        pt: 1,
                        pl: 0,
                        pr: 0,
                        borderTop: '1px solid #212121'
                    }}
                >
                    <Button
                        variant='contained'
                        disabled={selds ? false : true}
                        onClick={handleSubmit}
                    >
                        Classify
                    </Button>
                </Box>

            </Stack>
        </>
    )
}