import { Profile, UserActionObject, UserLoginAction, UserState } from "../types";
import USER_AT from "../types/UserActionTypes";
import { put, takeLatest } from "redux-saga/effects";
import { loginFulfilled } from "../actions/userActions";

const unauthenticatedState: UserState = {
    isLoggedIn: false,
    id: null,
    profile: null,
    // uid: null,
    // name: null,
    // email: null,
    accessToken: null,
    // role: null,
    tokenExpireOn: -1,
    currentUri: '/'
}

const initialState = { ...unauthenticatedState }

const userReducer = (state: UserState = initialState, action: UserActionObject) => {
    switch (action.type) {
        case USER_AT.USER_LOGIN_FULFILLED:
            const loggedin_profile: Profile = {
                uid: action.uid,
                avatar: action.avatar,
                role: action.role,
                user: {
                    id: action.id,
                    username: action.email,
                    email: action.email,
                    name: action.name
                }
            }
            return {
                ...state,
                id: action.id,
                isLoggedIn: true,
                accessToken: action.token,
                tokenExpireOn: action.expiration,
                profile: loggedin_profile
            }
        case USER_AT.USER_LOGOUT_FULFILLED:
            return { ...unauthenticatedState }
        case USER_AT.USER_SET_CURRENT_URI:
            return {
                ...state,
                currentUri: action.currentUri
            }
        
        default:
            return state
    }
}

export function* userLogInSaga() {
    yield takeLatest<UserLoginAction>(USER_AT.USER_LOGIN, function* loginAction(arg: UserLoginAction) {
        try {
            let expiration = new Date().getTime() / 1000
            if (arg.decodedIdToken) {
                expiration = arg.decodedIdToken.exp
            } else {
                expiration = expiration + 3600
            }
            yield put(loginFulfilled(arg.decodedIdToken.sub, 
                                    arg.decodedIdToken.uid, 
                                    arg.decodedIdToken.fullname, 
                                    arg.decodedIdToken?.email, 
                                    arg.decodedIdToken?.avatar, 
                                    arg.accessToken, 
                                    expiration,
                                    arg.decodedIdToken?.role))
            
        } 
        catch(e) {
            console.log("Error from function call.", e)
        }
        
    })
}

export default userReducer