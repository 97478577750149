import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom';

interface IDrawerList {
    onToggle: (open: boolean) => void
}
export const DrawerList = (props: IDrawerList) => {
    const { onToggle } = props
    const history = useHistory()
    return (
        <Box
            sx={{
                width: 250
            }}
            role="presentation"
            onClick={() => onToggle(false)}
            onKeyDown={() => onToggle(false)}
        >
        <nav aria-label='group-1'>
           <List>
           <ListItem key={'menu-1'} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </ListItem>
            </List>
        </nav>
        <Divider />
        <nav aria-label='group-2'>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Products
                    </ListSubheader>
                  }
            >
            <ListItem key={'menu-2'} disablePadding onClick={() => history.push('/capsuleendoscopy')}>
                <ListItemButton>
                    <ListItemIcon>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Capsule Endoscopy" />
                </ListItemButton>
            </ListItem>
            </List>
        </nav>
        <Divider />
        <nav aria-label='group-2'>
            <List>
            <ListItem key={'menu-3'} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItemButton>
            </ListItem>
            </List> 
        </nav>
        </Box>
    )
}