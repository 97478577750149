import { Fab, FabProps } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const CustomFab = styled((props: FabProps) => (
    <Fab
        size="small"
        color="primary"
        {...props} />
))(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        right: 42
    },
    [theme.breakpoints.up('lg')]: {
        right: 42
    },
    [theme.breakpoints.up('xl')]: {
        right: 10
    }
}))