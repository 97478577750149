import { logout } from "./actions/userActions";

const setupAxios = (axios: { interceptors: { request: { use: (arg0: (config: any) => any, arg1: (error: any) => Promise<never>) => void; }; response: { use: (arg0: (response: any) => any, arg1: (error: any) => Promise<unknown>) => void; }; }; }, store: { getState: () => { user: any }; dispatch: (arg0: any) => unknown; }) => {
  axios.interceptors.request.use(
    (config) => {
      const {
        user: { accessToken }
      } = store.getState();
      const revoke = config.url.includes('o/revoke_token/')
      if (accessToken && !revoke) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        //config.headers['Accept-Language'] = lang;
      } 
      if (revoke) {
        config.headers['Accept'] = 'application/json';
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      } else {
        config.headers['Accept'] = 'application/json';
        //config.headers['Accept-Language'] = lang;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      console.debug('Request Successful!', response);
      return response
    },
    (error) => {
      console.error('Request Failed:', error.config);
      const {
        user: { accessToken }
      } = store.getState();
      return new Promise((resolve, reject) => {

        // Return any error which is not due to authentication back to the calling service
        if (error.response.status === 401) {
          if (accessToken) {
            resolve(store.dispatch(logout(accessToken)))
          }
        } 
        
        reject(error);
      })
    }
  )
}

export default setupAxios
