import { Avatar, Box, Divider, Grid, IconButton, LinearProgress, linearProgressClasses, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, TablePagination, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material'
import { render } from '@testing-library/react'
import * as React from 'react'
import { styled } from '@mui/material/styles';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { toAbsoluteUrl } from '../../../../helpers';
import { fetchTasksAPI } from '../../../../reducers/func/dataManagementFunc';
import { Task } from '../../../../types';
import { CapsuleEndoscopyListItem } from '../listitems/CapsuleEndoscopyListItem';
import { CapsuleEndoscopyTaskContext } from '../../../../providers/CapsuleEndoscopyTaskContext';
import { on } from 'cluster';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: 150,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 2,
        backgroundColor: '#212121',
    },
}));

interface ICapsuleTaskList {
    // reload: boolean
    onTaskLoaded: (reload: boolean) => void
}
export const CapsuleEndoscopyList: React.FC<ICapsuleTaskList> = (props: ICapsuleTaskList) => {
    const [viewpref, setViewPref] = React.useState<string | null>('list')
    const [page, setPage] = React.useState(1)
    const [rowsPerPage, setRowPerPage] = React.useState(10)
    const [taskname, setTaskName] = React.useState(null)
    const [sortby, setSortby] = React.useState('-id')
    const [count, setCount] = React.useState(0)
    const [tasks, setTasks] = React.useState<Task[] | null>(null)
    const { state } = React.useContext(CapsuleEndoscopyTaskContext)
    const { reloadtasks } = state
    const { onTaskLoaded } = props

    const handleChangeView = (
        event: React.MouseEvent<HTMLElement>,
        newView: string | null,
    ) => {
        setViewPref(newView);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage + 1)
        onTaskLoaded(true)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowPerPage(parseInt(event.target.value, 10))
        onTaskLoaded(true)
    }

    // React.useEffect(() => {
    if (tasks === null || reloadtasks) {
        //fetch task
        fetchTasksAPI(0, taskname, page, sortby, rowsPerPage)
            .then((response) => {
                setTasks(response.data.results)
                setCount(response.data.count)
                onTaskLoaded(false)
            })
            .catch((error) => {
                console.log("Error fetching tasks: ", error)
            })
    }
    // }, [])

    return (
        <Paper
            square={true}
            elevation={0}
            sx={{
                width: '100%',
                height: '100%',
                padding: 0
            }}
        >
            <Stack spacing={0}>
                <Toolbar
                    key='toolbar'
                    variant='dense'
                    sx={{
                        minHeight: '38px',
                        borderBottom: '2px solid #212121'
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography variant='subtitle1'>Tasks</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                        <StyledToggleButtonGroup
                            size='small'
                            value={viewpref}
                            exclusive
                            onChange={handleChangeView}
                            aria-label="change task view"
                        >
                            <ToggleButton key="view-list" value="list" aria-label="list">
                                <ViewListIcon />
                            </ToggleButton>
                            <ToggleButton key="view-card" value="center" aria-label="card">
                                <ViewModuleIcon />
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </Box>
                </Toolbar>
                <Box sx={{ overflow: 'auto', maxHeight: '70vh' }}>
                    {tasks?.map((item: Task) => (
                        <>
                            <CapsuleEndoscopyListItem key={item.task_uid} task={item} />
                            <Divider />
                        </>
                    ))}
                </Box>


                <TablePagination
                    component="p"
                    count={count}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Stack>



        </Paper>
    )
}