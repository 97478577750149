import * as React from 'react';
import { Task } from '../../../../types';
import { Backdrop, Box, Button, Fade, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { task_status_map } from '../../../../helpers';
import CloseIcon from '@mui/icons-material/Close';
import { saveTaskSummaryAPI } from '../../../../reducers/func/dataManagementFunc';
import { set } from 'lodash';

interface ITaskProperties {
    task?: Task | null
}

export const TaskProperties: React.FC<ITaskProperties> = (props: ITaskProperties) => {
    const [openTaskSummaryModal, setOpenTaskSummaryModal] = React.useState<boolean>(false)
    const [taskSummary, setTaskSummary] = React.useState<string>('')
    const [edited, setEdited] = React.useState<boolean>(false)
    const { task } = props
    var task_date: Date = new Date()
    if (task?.task_date) {
        task_date = new Date(task.task_date)
    }

    const handleTaskSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEdited(true)
        setTaskSummary(event.target.value)
    }
    const saveTaskSummary = () => {
        console.log("Save Task Summary")
        saveTaskSummaryAPI(task?.id as string, taskSummary)
            .then((response) => {
                console.log("Task Summary saved: ", response)
                setEdited(false)
            })
            .catch((error) => {
                console.log("Error saving Task Summary: ", error)
            })
    }
    const handleTaskSummaryModalClose = () => {
        setOpenTaskSummaryModal(false)
    }

    React.useEffect(() => {
        if (task !== null && task !== undefined) {
            if (task.summary !== undefined && task.summary !== null) {
                setTaskSummary(task.summary)
            }
        }
    }, [task])

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Box

                sx={{
                    width: '100%',
                    height: 36,
                    backgroundColor: 'white',
                    pt: 1
                }}
            >
                <Toolbar
                    variant='dense'
                    sx={{
                        minHeight: 36,
                        borderBottom: '2px solid #212121',
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography variant='subtitle1'>Task Properties</Typography>
                    </Box>
                </Toolbar>

            </Box>

            <TableContainer sx={{ p: 0, width: '100%' }}>
                <Table size="small">
                    <TableBody>
                        <TableRow key="task-uid"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component={'th'} scope='row'>
                                UID:
                            </TableCell>
                            <TableCell>{task?.task_uid}</TableCell>
                        </TableRow>
                        <TableRow key="task-uid"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component={'th'} scope='row'>
                                Patient:
                            </TableCell>
                            <TableCell>{task?.patient?.refno}</TableCell>
                        </TableRow>
                        <TableRow key="task-uid"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component={'th'} scope='row'>
                                Institution:
                            </TableCell>
                            <TableCell>{task?.patient?.institution.name}</TableCell>
                        </TableRow>
                        <TableRow key="task-uid"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component={'th'} scope='row'>
                                Task Status:
                            </TableCell>
                            <TableCell>{(task?.status !== undefined) ? task_status_map.status[task?.status!] : ""}</TableCell>
                        </TableRow>
                        <TableRow key="task-uid"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component={'th'} scope='row'>
                                Task Date:
                            </TableCell>
                            <TableCell>{task?.task_date ? task_date.toISOString() : ""}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex' }}>
                <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    sx={{
                        m: 2,
                        width: '100%'
                    }}
                    onClick={() => setOpenTaskSummaryModal(true)}
                >
                    Task Summary
                </Button>
            </Box>

            <Modal
                aria-labelledby="task-summary-modal-title"
                aria-describedby="task-summary-modal-description"
                open={openTaskSummaryModal}
                onClose={handleTaskSummaryModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openTaskSummaryModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="task-summary-title" variant='subtitle1'>Edit Task Summary</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleTaskSummaryModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '200px',
                                    p: 1,
                                    overflow: 'auto'
                                }}
                            >
                                <TextField
                                    id="task-summary"
                                    label="Task Summary:"
                                    multiline
                                    rows={6}
                                    maxRows={12}
                                    sx={{ width: '100%', mt: 2 }}
                                    defaultValue={(task?.summary !== undefined && task.summary !== null) ? task.summary : ''}
                                    value={taskSummary}
                                    onChange={handleTaskSummaryChange}
                                    variant="standard"
                                />
                            </Box>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    disabled={!edited}
                                    sx={{
                                        mr: 2
                                    }}
                                    onClick={saveTaskSummary}>
                                    Save
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={handleTaskSummaryModalClose}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>



        </Box>
    )
}