import { Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Task } from '../../../../types'
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TaskFilesList } from '../lists/TaskFilesList';
import { SubtasksList } from '../lists/SubtasksList';
import { DatasetList } from '../lists/DatasetList';
import { GIOrganList } from '../lists/GIOrganList';
interface ICapsuleListItem {
    task: Task
}

export const CapsuleEndoscopyListItem: React.FC<ICapsuleListItem> = (props: ICapsuleListItem) => {
    const { task } = props
    const [expand, setExpand] = React.useState(false)
    const history = useHistory()
    // const runningsubtasks_acc = task.subtasks?.reduce((acc, x) => acc + (x.status===1 ? 1:0), 0)

    const handleExpandTaskDetail = () => {
        setExpand(!expand)
    }

    const handleWorkOnTask = () => {
        history.push(`/capsuleendoscopy/task/${task.task_uid}`)
    }

    return (
        <List disablePadding
            sx={{
                width: '100%'
            }}
        >
            <ListItem
                secondaryAction={
                    <>
                        <IconButton disableRipple edge='end' aria-label='work on task' onClick={handleWorkOnTask}>
                            <AssessmentIcon sx={{ fontSize: 28 }} />
                        </IconButton>
                        <IconButton disableRipple edge='end' aria-label='add a subtask'>
                            <PlaylistAddIcon sx={{ fontSize: 28 }} />
                        </IconButton>
                        <IconButton disableRipple edge='end' aria-label='remove task'>
                            <DeleteIcon sx={{ fontSize: 28 }} />
                        </IconButton>
                    </>
                }
            >
                {/* <ListItemIcon>
                    <IconButton edge='end' aria-label='showdetail' onClick={handleExpandTaskDetail}>
                        {expand ? <ExpandMore sx={{ fontSize: 30 }} /> : <ChevronRightIcon sx={{ fontSize: 30 }} />}
                    </IconButton>

                </ListItemIcon> */}
                <ListItemAvatar>
                    <AssignmentIcon sx={{ fontSize: 64 }} />
                </ListItemAvatar>
                <ListItemText
                    primary={task.task_name}
                // secondary={
                //     <React.Fragment>
                //         <Stack>
                //         <Typography variant='subtitle1'>
                //             Running Subtasks: {runningsubtasks_acc}
                //         </Typography>

                //         </Stack>

                //     </React.Fragment>

                // }
                />
            </ListItem>
            {/* <Collapse in={expand} timeout="auto" unmountOnExit>

                <>
                    <TaskFilesList task={task.id} />
                    <Divider variant='inset' />
                </>

                <>
                    <SubtasksList task={task.id} />
                    <Divider variant='inset' />
                </>

                <>
                    <DatasetList task={task.id} />
                    <Divider variant='inset' />
                </>

                <>
                    <GIOrganList task={task.id} />
                    <Divider variant='inset' />
                </>

            </Collapse> */}
        </List>

    )
}