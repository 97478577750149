import axios from "axios"
import { client_id, client_secret, rest_endpoint } from "../../settings"
import { HealthCareInstitution, Patient, Task } from "../../types/DataStateTypes"

export const fetchInstitutionAPI = () => {
    let url = `${rest_endpoint}healthcares/`
    return axios.get<any>(url)
}

export const fetchPatientsAPI = (institutionId: string) => {
    let url = `${rest_endpoint}healthcares/${institutionId}/patients/`
    return axios.get<any>(url)
}

export const fetchPhysiciansAPI = () => {
    let url = `${rest_endpoint}profiles/physicians/`
    return axios.get<any>(url)
}

export const fetchTasksAPI = (product: number, taskname: any, page: number, sortby: string, rows_per_page: number) => {
    let url = `${rest_endpoint}tasks/?product=${product}${taskname ? `&taskname=${taskname}` : ''}&page=${page}&ordering=${sortby}&page_size=${rows_per_page}`
    return axios.get<any>(url)
}

export const fetchTaskAPI = (task_uid: string) => {
    let url = `${rest_endpoint}tasks/get_task_by_uid/?task_uid=${task_uid}`
    return axios.get<any>(url)
}

export const addInstitutionAPI = (item: HealthCareInstitution) => {
    let formData = new FormData()
    formData.append("type", item.type as string)
    formData.append("name", item.name as string)
    let url = `${rest_endpoint}healthcares/add/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const addPatientAPI = (item: Patient) => {
    let formData = new FormData()
    formData.append("refno", item.refno as string)
    if (item.firstname) formData.append("firstname", item.firstname as string)
    if (item.lastname) formData.append("lastname", item.lastname as string)
    if (item.birthdate) formData.append("birthdate", item.birthdate as string)
    if (item.height) formData.append("height", item.height as unknown as string)
    if (item.weight) formData.append("weight", item.weight as unknown as string)

    let url = `${rest_endpoint}healthcares/${item.institution.id}/addpatient/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const addTaskAPI = (item: Task) => {
    let formData = new FormData()
    formData.append("taskname", item.task_name)
    formData.append("product", item.product as unknown as string)
    formData.append("patient", item.patient?.id as string)
    formData.append("physician", item.physician as string)
    let url = `${rest_endpoint}tasks/savenew/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const uploadAPI = (task: number, chunk: Blob, name: string, last: boolean, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("chunk", chunk)
    formData.append("name", name)
    formData.append("lastchunk", last as unknown as string)
    let url = `${rest_endpoint}tasks/${task}/uploaddatav2/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
}

export const deleteFileSourceAPI = (file_id: string) => {
    let url = `${rest_endpoint}filesources/${file_id}/delete/`
    return axios.post(url)
}

export const fetchImageDataAPI = (ds: string, sample_min: number, sample_max: number, page: number) => {
    let url = `${rest_endpoint}dataframes/?ds=${ds}${sample_min ? `&sample_min=${sample_min}` : ''}${sample_max ? `&sample_max=${sample_max}` : ''}&page=${page}`
    return axios.get<any>(url)
}

export const fetchImageDataByTSAPI = (ds: string, ts_min: number, ts_max: number, page: number) => {
    let url = `${rest_endpoint}dataframes/?ds=${ds}${ts_min ? `&ts_min=${ts_min}` : ''}${ts_max ? `&ts_max=${ts_max}` : ''}&page=${page}`
    return axios.get<any>(url)
}

export const fetchGIOrganLabelAPI = (classification: string, label: number | null, page: number, rows_per_page: number) => {
    let url = `${rest_endpoint}giorganlabels/?classification=${classification}${label && label >= 0 ? `&label=${label}` : ''}&page=${page}&page_size=${rows_per_page}`
    return axios.get<any>(url)
}

export const fetchSubtasksAPI = (task: string) => {
    let url = `${rest_endpoint}tasks/${task}/subtasks/`
    return axios.get<any>(url)
}

export const fetchFilesListAPI = (task: string) => {
    let url = `${rest_endpoint}tasks/${task}/filesources/`
    return axios.get<any>(url)
}

export const fetchDatasetsAPI = (task: string) => {
    let url = `${rest_endpoint}tasks/${task}/datasets/`
    return axios.get<any>(url)
}

export const fetchDatasetAPI = (ds: string) => {
    let url = `${rest_endpoint}datasets/${ds}/`
    return axios.get<any>(url)
}

export const fetchGIOrgansAPI = (task: string) => {
    let url = `${rest_endpoint}tasks/${task}/giorgans/`
    return axios.get<any>(url)
}

export const fetchLesionsAPI = (task: string) => {
    let url = `${rest_endpoint}tasks/${task}/lesions/`
    return axios.get<any>(url)
}

export const fetchModelsAPI = (type: string) => {
    let url = `${rest_endpoint}models/?type=${type}`
    return axios.get<any>(url)
}

export const createDatasetAPI = (task: string, filesrc: string) => {
    let formData = new FormData()
    formData.append("filesrc", filesrc)
    let url = `${rest_endpoint}tasks/${task}/extractdata/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const deleteDatasetAPI = (ds: string) => {
    let url = `${rest_endpoint}datasets/${ds}/delete/`
    return axios.post(url)
}

export const giorganClassifyAPI = (task: string, dataset: string, model: string) => {
    let formData = new FormData()
    formData.append("dataset", dataset)
    formData.append("model", model)
    let url = `${rest_endpoint}tasks/${task}/organclassify/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const deleteGIOCAPI = (gioc: string) => {
    let url = `${rest_endpoint}giorganclassifications/${gioc}/delete/`
    return axios.post(url)
}

export const fetchAllGIOrganLabelsAPI = (gioc: string) => {
    let url = `${rest_endpoint}giorganclassifications/${gioc}/getalllabels/`
    return axios.get(url)
}

export const updateMarkAPI = (ds: string, organ: string, sample: number) => {
    let formData = new FormData()
    formData.append("organ", organ)
    formData.append("sample", sample as unknown as string)
    let url = `${rest_endpoint}datasets/${ds}/setmark/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const editLabelAPI = (labelitem: string, label: number) => {
    let formData = new FormData()
    formData.append("label", label as unknown as string)
    let url = `${rest_endpoint}giorganlabels/${labelitem}/editlabel/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const fetchLesionLabelAPI = (classification: string, label: number | null, page: number, rows_per_page: number) => {
    let url = `${rest_endpoint}lesionlabels/?classification=${classification}${label && label >= 0 ? `&label=${label}` : ''}&page=${page}&page_size=${rows_per_page}`
    return axios.get<any>(url)
}

export const lesionDetectAPI = (task: string, giorgan: string, model: string) => {
    let formData = new FormData()
    formData.append("giorgan", giorgan)
    formData.append("model", model)
    let url = `${rest_endpoint}tasks/${task}/detectlesions/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const classifyAndDetectAPI = (task: string, dataset: string, organmodel: string, lesionmodel: string) => {
    let formData = new FormData()
    formData.append("dataset", dataset)
    formData.append("organmodel", organmodel)
    formData.append("lesionmodel", lesionmodel)
    let url = `${rest_endpoint}tasks/${task}/classifyorgans_and_detectlesions/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const deleteLesionDetAPI = (lesiondet: string) => {
    let url = `${rest_endpoint}lesiondetections/${lesiondet}/delete/`
    return axios.post(url)
}

export const editLesionLabelAPI = (labelitem: string, label: number) => {
    let formData = new FormData()
    formData.append("label", label as unknown as string)
    let url = `${rest_endpoint}lesionlabels/${labelitem}/editlabel/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const applyOrganLabelAPI = (labelitem: string, label: number) => {
    let formData = new FormData()
    formData.append("label", label as unknown as string)
    let url = `${rest_endpoint}giorganlabels/${labelitem}/applylabelchange/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const applyLesionLabelAPI = (labelitem: string, label: number, lesion_type: number, symptoms: string) => {
    let formData = new FormData()
    formData.append("label", label as unknown as string)
    formData.append("lesion_type", lesion_type as unknown as string)
    formData.append("symptoms", symptoms)
    let url = `${rest_endpoint}lesionlabels/${labelitem}/applylabelchange/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const applyImageDataframeTSAPI = (dataframe: string, ts: number) => {
    let formData = new FormData()
    formData.append("ts", ts as unknown as string)
    let url = `${rest_endpoint}dataframes/${dataframe}/applytschange/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const importTSdataAPI = (dataset: string, file: File) => {
    let formData = new FormData()
    formData.append("ts_data", file)
    let url = `${rest_endpoint}datasets/${dataset}/importtsdata/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const saveTaskSummaryAPI = (task: string, summary: string) => {
    let formData = new FormData()
    formData.append("summary", summary)
    let url = `${rest_endpoint}tasks/${task}/save_summary/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const markorganforexportAPI = (item: string, labels: number[]) => {
    const data = {
        labels: labels
    }
    let url = `${rest_endpoint}giorganclassifications/${item}/markforexport/`
    return axios.post<any>(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const unmarkorganforexportAPI = (item: string, labels: number[]) => {
    const data = {
        labels: labels
    }
    let url = `${rest_endpoint}giorganclassifications/${item}/unmarkforexport/`
    return axios.post<any>(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const marklesionforexportAPI = (item: string, labels: number[]) => {
    const data = {
        labels: labels
    }
    let url = `${rest_endpoint}lesiondetections/${item}/markforexport/`
    return axios.post<any>(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const unmarklesionforexportAPI = (item: string, labels: number[]) => {
    const data = {
        labels: labels
    }
    let url = `${rest_endpoint}lesiondetections/${item}/unmarkforexport/`
    return axios.post<any>(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const exportVCEReportAPI = (task: string, giorgan: string, lesiondet: string) => {
    let formData = new FormData()
    formData.append("giorgan", giorgan)
    formData.append("lesiondet", lesiondet)
    let url = `${rest_endpoint}tasks/${task}/export_vce_report/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}