import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'

export const Footer: React.FC = (props) => {
    const today = new Date().getFullYear()
    
    return (
        <div
            id="app_footer"
            style={{
                position: 'fixed',
                bottom: 0,
                left:0,
                right: 0,
                zIndex: 1500,
                width: '100%',
                height: '35px',
                backgroundColor: 'white'
            }}
        >
            <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Typography variant="caption">Copyright {today.toString()} &copy;{" "}GutDX CC.</Typography>
                <Typography variant="caption"><Link to="/terms">Terms of Services</Link></Typography>
                <Typography variant="caption"><Link to="/privacy">Privacy Policy</Link></Typography>
            </Stack>
        </div>
    )
}