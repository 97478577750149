import * as React from 'react'
import { Route, Switch } from "react-router-dom"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { LayOut } from '../layouts';
import Base from './Base';
import RedirectAfterLogIn from './RedirectAfterLogIn';
import Signin from './Signin';
import Signout from './Signout';

const locationHelper = locationHelperBuilder({})
const now = (new Date()).getTime() / 1000
const userIsNotAuthenticated = connectedRouterRedirect<any, any>({
    // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || state.user.currentUri,
    // This prevents us from adding the query parameter when we send the user away from the login page
    allowRedirectBack: false,
    // This prevents us from adding the query parameter when we send the user away from the login page
    // Determine if the user is authenticated or not
    authenticatedSelector: state => !state.user.isLoggedIn || (state.user.tokenExpireOn <= now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsNotAuthenticated'
})
const userIsAuthenticated = connectedRouterRedirect<any, any>({
    // The url to redirect user to if they fail
    redirectPath: '/signin',
    // Determine if the user is authenticated or not
    authenticatedSelector: state => state.user.isLoggedIn && (state.user.tokenExpireOn > now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
})

const RedirectedLogin = userIsNotAuthenticated(Signin)
const RedirectedAfterLogIn = userIsNotAuthenticated(RedirectAfterLogIn)
const RedirectedLogOut = userIsAuthenticated(Signout)

class Component extends React.Component {
    render() {
        return (
            <Switch>

                <Route exact path="/signin" component={RedirectedLogin} />
                <Route exact path="/signout" component={RedirectedLogOut} />
                <Route exact path="/redirectafterlogin" component={RedirectedAfterLogIn} />
                <Route
                    render={() => {
                        return (
                            <LayOut>
                                <Base />
                            </LayOut>
                        )
                    }}
                />
            </Switch>

        )
    }
}

export default Component