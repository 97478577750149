interface Itask_status_map {
    status: Record<number, string>
}
export const task_status_map: Itask_status_map = {
    status: {
        0: 'task created',
        1: 'task assigned',
        2: 'task completed',
        3: 'task cancelled'
    }
}