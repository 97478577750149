import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material'
import * as React from 'react'
import { fetchGIOrgansAPI, fetchModelsAPI, lesionDetectAPI } from '../../../../reducers/func/dataManagementFunc'
import { GIOrgan, MLModel } from '../../../../types'
import { SelectChangeEvent } from '@mui/material/Select';
interface INewLesion {
    task?: string
    onSuccess: () => void
}

export const NewLesionForm: React.FC<INewLesion> = (props: INewLesion) => {
    const [giorgans, setGIOrgans] = React.useState<GIOrgan[] | null>(null)
    const [selgi, setSelGI] = React.useState<string | null>(null)
    const [models, setModels] = React.useState<MLModel[] | null>(null)
    const [selmodel, setSelModel] = React.useState<string>('0')
    const { task, onSuccess } = props

    const handleGIOrganChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelGI((event.target as HTMLInputElement).value as string);
    }

    const handleModelChange = (event: SelectChangeEvent) => {
        setSelModel(event.target.value)
    }

    const handleSubmit = () => {
        lesionDetectAPI(task as string, selgi as string, selmodel as string)
            .then((response) => {
                onSuccess()
            })
            .catch((error) => {
                console.log("Error creating small bowel lesion detection task: ", error)
            })
    }

    React.useEffect(() => {
        if (task && models == null) {
            // fetch files list
            fetchModelsAPI('1')
                .then((response) => {
                    setModels(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching models: ", error)
                })
        }
        if (task && giorgans == null) {
            // fetch files list
            fetchGIOrgansAPI(task)
                .then((response) => {
                    setGIOrgans(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching datasets: ", error)
                })
        }
    }, [])

    return (
        <>
            <Stack spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        height: '180px',
                        overflow: 'auto'
                    }}
                >
                    <FormControl>
                        <FormLabel id="dsselect-rgroup-label">Select organ label to be detected for lesions</FormLabel>
                        <RadioGroup
                            aria-labelledby="dsselect-rgroup-label"
                            name="dsselect-rgroup"
                            onChange={handleGIOrganChange}
                            sx={{
                                pl: 2
                            }}
                        >
                            {giorgans && giorgans.map((giorgan: GIOrgan) => (
                                <FormControlLabel value={giorgan.id} control={<Radio />} label={`GIOC-${giorgan.id}-${giorgan.dataset?.dataset_uid.split('-')[4]}`} />
                            ))}


                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="model-select-standard-label">Classification Model</InputLabel>
                        <Select
                            labelId="model-select-standard-label"
                            id="model-select-standard"
                            value={selmodel}
                            onChange={handleModelChange}
                            label="model"
                        >
                            {models && models.map((mm: MLModel) => (
                                <MenuItem value={mm.id}>${mm.model_architecture}</MenuItem>
                            ))}


                        </Select>
                    </FormControl>
                </Box>
                <Box
                    justifyContent='flex-end'
                    sx={{
                        display: 'flex',
                        minHeight: '38px',
                        pt: 1,
                        pl: 0,
                        pr: 0,
                        borderTop: '1px solid #212121'
                    }}
                >
                    <Button
                        variant='contained'
                        disabled={selgi ? false : true}
                        onClick={handleSubmit}
                    >
                        Detect
                    </Button>
                </Box>

            </Stack>
        </>
    )
}