import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import { LayoutSplashScreen, SplashscreenProvider } from './layouts'
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './reducers/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import setupAxios from './setupAxios';
import axios from 'axios';

setupAxios(axios, store)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <SplashscreenProvider>
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <App />
          </React.Suspense>
        </PersistGate>
    </Provider>
  </SplashscreenProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
