import { Box, Container, Stack, Toolbar, Typography } from '@mui/material'
import { bindActionCreators } from 'redux'
import * as React from 'react'
import { setCurrentUri } from '../actions/userActions'
// import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { toAbsoluteUrl } from '../helpers'


class Component extends React.Component<any, {}> {

    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        const { user, setCurrentUri } = this.props
        setCurrentUri('/dashboard')
    }

    render() {
        return (
            <>
                <Stack spacing={0}>
                <Box
                    sx={{
                        width: '100%',
                        height: 42,
                        backgroundColor: 'white'
                    }}
                >
                    <Toolbar
                        variant='dense'
                        sx={{
                            minHeight: 42
                        }}
                    >
                    <Typography variant='subtitle1'>Dashboard</Typography>
                    </Toolbar>
                    
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="85vh"
                >
                    <Stack
                    justifyContent="center"
                    alignItems="center"
                    >
                    <img src={toAbsoluteUrl('/media/misc/graphics/underconstruction.png')} width={224} />
                    <Typography variant='body1'>This page is under construction</Typography>
                    </Stack>
                    
                </Box>
                </Stack>

            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
// const stateProps = returntypeof(mapStateToProps)
// const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Component);