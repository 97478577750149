import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import userReducer, { userLogInSaga } from './userReducer'

const rootReducer = combineReducers({
    user: userReducer
})

export default rootReducer
export function* rootSaga() {
    yield all([userLogInSaga()])
}
