import * as React from 'react'
import { useForm } from 'react-hook-form'
import { GIOrganLabel } from '../../../../types'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { applyOrganLabelAPI } from '../../../../reducers/func/dataManagementFunc'

interface IEditOrganLabel {
    organlabel: GIOrganLabel
    onEdit: (organlabel: GIOrganLabel) => void
}

export const EditOrganLabel: React.FC<IEditOrganLabel> = (props: IEditOrganLabel) => {
    const [edited, setEdited] = React.useState<boolean>(false)
    const { organlabel, onEdit } = props
    const form = useForm()

    const handleOrganLabelChange = (event: SelectChangeEvent) => {
        const label = parseInt(event.target.value)
        // setSelLabel(label)
        setEdited(true)
        const neworganlabel: GIOrganLabel = {
            ...organlabel,
            label: label,
            edited: true
        }
        onEdit(neworganlabel)
    }

    const handleApplyChange = () => {
        applyOrganLabelAPI(organlabel.id as string, organlabel.label)
            .then((response) => {
                setEdited(false)
                console.log("Apply change success: ", response)
                onEdit({ ...organlabel, edited: false })
            })
            .catch((error) => {
                console.log("Error applying change: ", error)
            })
    }

    React.useEffect(() => {
        if (organlabel !== null && organlabel !== undefined) {

            if (organlabel.edited !== undefined && organlabel.edited !== null) {
                setEdited(organlabel.edited)
            } else {
                setEdited(false)
            }

        }
    }, [organlabel])

    return (
        <form>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                <Grid item xs={6}>
                    <Grid container direction='column' spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <Box>
                                    <Typography variant='body2'>Id: {(organlabel !== undefined && organlabel !== null) ? organlabel.id : ''} </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <FormControl variant="standard">
                                    <InputLabel id="lesionlabel-select-standard-label">Organ Label</InputLabel>
                                    <Select
                                        labelId="lesionlabel-select-standard-label"
                                        id="lesionlabel-select-standard"
                                        value={(organlabel !== undefined ? organlabel.label : -1) as unknown as string}
                                        label="lesionlabel"
                                        color="primary"
                                        sx={{ width: "107px" }}
                                        onChange={handleOrganLabelChange}
                                    >
                                        <MenuItem value={-1}>Unknown</MenuItem>
                                        <MenuItem value={0}>Esophagus</MenuItem>
                                        <MenuItem value={1}>Stomach</MenuItem>
                                        <MenuItem value={2}>Small Bowel</MenuItem>
                                        <MenuItem value={3}>Colon</MenuItem>
                                    </Select>
                                </FormControl>

                            </Stack>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' spacing={1} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                        <Button
                            variant='contained'
                            disabled={!edited}
                            onClick={handleApplyChange}
                        >
                            Apply change
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}