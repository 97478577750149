import { Container } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { setCurrentUri } from '../actions/userActions'
import { guid } from '../helpers'
import { authorize_url, client_id, public_url, redirect_uri } from '../settings'

class Component extends React.Component<any, {}> {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        let origin: string = encodeURIComponent(`${public_url}/signin`)
        let authorizer = `${authorize_url}?response_type=id_token%20token&scope=openid%20read%20write&client_id=${client_id}&redirect_uri=${redirect_uri}&origin=${origin}&state=${guid()}&nonce=${guid()}`
        window.location.assign(authorizer)
    }

    render() {
        return (
            <Container maxWidth="md">
                {/* <SignInComponent /> */}
            </Container>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
// const stateProps = returntypeof(mapStateToProps)
// const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    // connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
    connect(mapStateToProps, mapDispatchToProps)
)(Component);