import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme(
    {
        breakpoints: {
            // mobile < md
            // md < tablet  < lg
            // lg < laptop < md
            // desktop > xl
            // custom breakpoint
            values: {
                xs: 0,
                sm: 576,   
                md: 768,  
                lg: 1024,  
                xl: 1400
            },
        },
        typography: {
            fontFamily: ["Poppins", "Prompt", "sans-serif"].join(","),
            fontWeightLight: 200,
            fontWeightRegular: 300,
            fontWeightMedium: 400,
            fontWeightBold: 600,
            body1: {
                fontSize: 11
            },
            subtitle1: {
                fontSize: 12,
                fontWeight: 400
            }
        },
        palette: {
            primary: {
                main: "#212121",
                light: "#484848",
                dark: "#000000",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#616161",
                light: "#8e8e8e",
                dark: "#373737",
                contrastText: "#ffffff"
            },
            error: {
                main: "#f50057"
            }
        },
        components: {
            MuiPopover: {
                defaultProps: {
                    elevation: 2
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: false
                }
            }
        }
    }
);

export function MaterialUIThemeProvider(props: { children: any; }) {
    const { children } = props;
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}