import { AppBar, Box, Grid, IconButton, InputBase, SwipeableDrawer, Toolbar, Typography } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import * as React from 'react'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { UserToggler } from './widgets/togglers/UserToggler'
import SearchIcon from '@mui/icons-material/Search';
import { DrawerList } from './widgets/lists/DrawerList'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
   

export const MainAppbar: React.FC = () => {

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const handleToggleDrawer = (open: boolean) => {
        setDrawerOpen(open)
    }

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={1} 
            sx={{ 
                height: '48px', 
                zIndex: 1000,
                position: 'fixed',
                top: '0',
                right: '0',
                left: '0', 
                paddingLeft: '15px',
                paddingRight: '15px',
            }}>
            <Toolbar disableGutters variant="dense"
                sx={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                }}
            >
            
            <Box sx={{ display: { xs: 'none', md: 'flex'} }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => handleToggleDrawer(true)}
                    color="secondary"
                    >
                    <MenuIcon fontSize='inherit' />
                </IconButton>
                
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex'}}}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant='h4' sx={{ fontWeight: 200 }}>GutDX</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant='h6' sx={{ fontWeight: 100, fontStyle: 'italic', pt: '8px' }}>Analytic</Typography>
                    </Grid>
                </Grid>  
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'}}}>
                <Search>
                <SearchIconWrapper>
                <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                />
                </Search>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'} }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => handleToggleDrawer(true)}
                    color="secondary"
                    >
                    <MenuIcon fontSize='inherit' />
                </IconButton>
                
            </Box>
            


            <Box sx={{ ml: 1, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant='h4' sx={{ fontWeight: 200 }}>GutDX</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ pl: '0px !important' }}>
                    <Typography variant='h6' sx={{ fontWeight: 100, fontStyle: 'italic', pt: '8px' }}>Analytic</Typography>
                    </Grid>
                </Grid>  
            
            </Box>
            
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
                <UserToggler />
            </Box>
            </Toolbar>
        </AppBar>
        </Box>
        <SwipeableDrawer
            anchor="left"
            open={drawerOpen}
            onClose={(e) => handleToggleDrawer(false)}
            onOpen={(e) => handleToggleDrawer(true)}
        >
            <DrawerList onToggle={handleToggleDrawer} />
        </SwipeableDrawer>
        </>
    )

}