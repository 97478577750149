import * as React from 'react'

export interface CapsuleEndoscopyTaskRefs {
    
}
export interface CapsuleEndoscopyTaskContextInterface {
    state: any
    dispatch: any
    refs: any
} 
const capsuleEndoscopyTaskContext: CapsuleEndoscopyTaskContextInterface = {
    state: {},
    dispatch: {},
    refs: {}
}

export const CapsuleEndoscopyTaskContext = React.createContext(capsuleEndoscopyTaskContext)