import { Backdrop, Box, Button, Fade, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Stack, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
// import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { exportVCEReportAPI, fetchGIOrgansAPI, fetchLesionsAPI, fetchTaskAPI } from '../reducers/func/dataManagementFunc'
import { GIOrgan, Lesion, Task } from '../types'
import { TaskFilesTable } from '../layouts/components/widgets/tables/TaskFilesTable'
import { TaskProperties } from '../layouts/components/widgets/views/TaskProperties'
import { DatasetList } from '../layouts/components/widgets/lists/DatasetList'
import { GIOrganList } from '../layouts/components/widgets/lists/GIOrganList'
import { GILesionList } from '../layouts/components/widgets/lists/GILesionList'
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Link } from 'react-router-dom'

class Component extends React.Component<any, {
    task?: Task | null,
    openExportReportModal: boolean,
    exportGIOC: string | null,
    exportLesion: string | null,
    giocList: GIOrgan[],
    lesionList: Lesion[],
    isExporting: boolean,
    exportDone: boolean,
    reportURL?: string
}> {

    constructor(props: any) {
        super(props)
        this.handleExportReportModalClose = this.handleExportReportModalClose.bind(this)
        this.handleExportReportModalOpen = this.handleExportReportModalOpen.bind(this)
        this.handleExportGIOCChange = this.handleExportGIOCChange.bind(this)
        this.handleExportLesionChange = this.handleExportLesionChange.bind(this)
        this.handleExporting = this.handleExporting.bind(this)
        this.exportReport = this.exportReport.bind(this)
        this.state = {
            task: null,
            openExportReportModal: false,
            giocList: [],
            lesionList: [],
            exportGIOC: null,
            exportLesion: null,
            isExporting: false,
            exportDone: false,
            reportURL: undefined
        }
    }

    componentDidMount() {
        const { taskid } = this.props.match.params
        this.fetchTask(taskid)
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{ task?: Task | null; openExportReportModal: boolean; exportGIOC: string | null; exportLesion: string | null; giocList: GIOrgan[]; lesionList: Lesion[]; isExporting: boolean }>, snapshot?: any): void {
        if (this.state.isExporting) {
            this.exportReport()
        }
    }

    fetchTask = async (taskid: string) => {
        try {
            const task = await fetchTaskAPI(taskid)
            const giocList = await fetchGIOrgansAPI(task.data.id)
            const lesionList = await fetchLesionsAPI(task.data.id)
            this.setState({
                ...this.state,
                task: task.data,
                giocList: giocList.data,
                lesionList: lesionList.data
            })
        } catch (error) {
            console.log("Error fetching task: ", error)
        }
    }

    handleExportReportModalClose = () => {
        if (this.state.exportDone) {

            this.setState({ ...this.state, exportDone: false, reportURL: undefined, openExportReportModal: false })
        }
        else {
            this.setState({ ...this.state, openExportReportModal: false })
        }
    }

    handleExportReportModalOpen = () => {
        this.setState({ ...this.state, openExportReportModal: true })
    }

    handleExportGIOCChange = (event: SelectChangeEvent<string | null>) => {
        this.setState({ ...this.state, exportGIOC: event.target.value as string })
    }

    handleExportLesionChange = (event: SelectChangeEvent<string | null>) => {
        this.setState({ ...this.state, exportLesion: event.target.value as string })
    }

    handleExporting = () => {
        this.setState({ ...this.state, isExporting: true })
    }

    exportReport = async () => {
        try {
            const response = await exportVCEReportAPI(this.state.task?.id as string, this.state.exportGIOC as string, this.state.exportLesion as string)
            console.log("Export report response: ", response)
            this.setState({ ...this.state, isExporting: false, exportDone: true, reportURL: response.data.report_url })
        }
        catch (error) {
            console.log("Error exporting report: ", error)
        }
    }


    render() {
        const { task } = this.state;
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Box

                    sx={{
                        width: '100%',
                        height: 42,
                        backgroundColor: 'white'
                    }}
                >
                    <Toolbar
                        variant='dense'
                        sx={{
                            minHeight: 42
                        }}
                    >
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            {(task !== null && task !== undefined) ? (
                                <Typography variant='subtitle1'>{task.task_name}</Typography>
                            ) : (<Typography variant='subtitle1'>Task Not Found</Typography>)}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Button
                                variant='outlined'
                                color='primary'
                                size='small'
                                sx={{
                                    marginRight: 2
                                }}
                                onClick={this.handleExportReportModalOpen}>
                                Export Report
                            </Button>
                        </Box>
                    </Toolbar>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'calc(100% - 77px)'
                    }}
                >
                    <Stack direction='row' spacing={2}
                        sx={{
                            width: '100%',
                            height: '50%',
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="left"
                            flexGrow={1}
                            flexShrink={1}
                            paddingTop="10px"
                            paddingBottom="0px"
                            paddingLeft="12px"
                            paddingRight="0px"
                            sx={{
                                width: '70%'
                            }}
                        >
                            <Paper
                                square={true}
                                elevation={0}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 0
                                }}
                            >
                                {(task !== null && task !== undefined) && (
                                    <TaskFilesTable task={task.id} />
                                )}
                            </Paper>

                        </Box>
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="left"
                            flexGrow={1}
                            flexShrink={1}
                            paddingTop="10px"
                            paddingBottom="0px"
                            paddingLeft="0px"
                            paddingRight="12px"
                            sx={{
                                width: '30%'
                            }}
                        >
                            <Paper
                                square={true}
                                elevation={0}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 0
                                }}
                            >
                                {(task !== null && task !== undefined) && (
                                    <TaskProperties task={task} />
                                )}
                            </Paper>

                        </Box>
                    </Stack>
                    <Stack direction='row' spacing={2}
                        sx={{
                            width: '100%',
                            height: '50%',
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="left"
                            flexGrow={1}
                            flexShrink={1}
                            paddingTop="10px"
                            paddingBottom="10px"
                            paddingLeft="12px"
                            paddingRight="12px"
                            sx={{
                                width: '30%'
                            }}
                        >
                            <Paper
                                square={true}
                                elevation={0}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 0
                                }}
                            >
                                {(task !== null && task !== undefined) && (
                                    <DatasetList task={task.id} />
                                )}
                            </Paper>

                        </Box>
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="left"
                            flexGrow={1}
                            flexShrink={1}
                            paddingTop="10px"
                            paddingBottom="10px"
                            paddingLeft="12px"
                            paddingRight="12px"
                            sx={{
                                width: '30%'
                            }}
                        >
                            <Paper
                                square={true}
                                elevation={0}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 0
                                }}
                            >
                                {(task !== null && task !== undefined) && (
                                    <GIOrganList task={task.id} />
                                )}
                            </Paper>

                        </Box>
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="left"
                            flexGrow={1}
                            flexShrink={1}
                            paddingTop="10px"
                            paddingBottom="10px"
                            paddingLeft="12px"
                            paddingRight="12px"
                            sx={{
                                width: '30%'
                            }}
                        >
                            <Paper
                                square={true}
                                elevation={0}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 0
                                }}
                            >
                                {(task !== null && task !== undefined) && (
                                    <GILesionList task={task.id} />
                                )}
                            </Paper>

                        </Box>
                    </Stack>
                </Box>
                <Modal
                    aria-labelledby="export-report-modal-title"
                    aria-describedby="export-report-modal-description"
                    open={this.state.openExportReportModal}
                    onClose={this.handleExportReportModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openExportReportModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="addpatient-modal-title" variant='subtitle1'>Export VCE Report</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={this.handleExportReportModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    {this.state.exportDone ? (
                                        <>
                                            <Typography variant='body1'>Report exported successfully. You can access the report at this URL:</Typography>
                                            <div>
                                                <a href={this.state.reportURL} target="_blank" rel="noreferrer">{this.state.reportURL}</a>
                                            </div>
                                        </>
                                    ) : (
                                        <Stack spacing={1}>
                                            <FormControl variant="standard" sx={{ m: 1, width: '200px' }}>
                                                <InputLabel id="gioc-select-standard-label">GI Organ Classification subtask</InputLabel>
                                                <Select
                                                    labelId="gioc-select-standard-label"
                                                    id="gioc-select-standard"
                                                    value={this.state.exportGIOC}
                                                    onChange={this.handleExportGIOCChange}
                                                    label="GI Organ Classification subtask"
                                                >
                                                    {this.state.giocList.map((gioc) => (
                                                        <MenuItem key={gioc.id} value={gioc.id}>GIOC-{gioc.id}-{gioc.dataset?.dataset_uid.split('-')[4]}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                                                <InputLabel id="lesion-select-standard-label">Lesion Detection subtask</InputLabel>
                                                <Select
                                                    labelId="lesdion-select-standard-label"
                                                    id="lesion-select-standard"
                                                    value={this.state.exportLesion}
                                                    onChange={this.handleExportLesionChange}
                                                    label="Lesion Detection subtask"
                                                >
                                                    {this.state.lesionList.map((lesion) => (
                                                        <MenuItem key={lesion.id} value={lesion.id}>SMBL-{lesion.id}-{lesion.dataset?.dataset_uid.split('-')[4]}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                    )}
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={this.handleExporting}
                                        sx={{
                                            mr: 2
                                        }}
                                        disabled={this.state.exportGIOC === null || this.state.exportLesion === null}
                                    >
                                        Export
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={this.handleExportReportModalClose}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        //setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
// const stateProps = returntypeof(mapStateToProps)
// const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Component);