import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputLabel, Menu, MenuItem, MenuProps, Radio, RadioGroup, Select, SelectChangeEvent, Slider, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles';
import { GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, Lesion, LesionLabel, LesionLabelViewset, instanceofImageDataframe, instanceofLesionLabel } from '../../../../types'
import { ImageDataframeList } from '../lists/ImageDataframeList'
// import { Image, Layer, Stage } from 'react-konva'
// import { CustomFab } from '../../controls/CustomControls'
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import { useForm } from 'react-hook-form'
import { LesionLabelImageView } from './LesionLabelImageView'
import { EditLesionLabel } from '../forms/EditLesionLabel'
import { Heatmap } from '../../charts/Heatmap'


interface ILesionLabelView {
    lesion: Lesion | null
}

const DropDownMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
export const LesionLabelView: React.FC<ILesionLabelView> = (props: ILesionLabelView) => {
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [lesionlabelviewset, setlesionLabelViewset] = React.useState<LesionLabelViewset | null>(null)
    const [viewoption, setViewOption] = React.useState<string | null>('thumb')
    const [ddanchorEl, setDDAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(ddanchorEl);
    const handleDDMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setDDAnchorEl(event.currentTarget);
    }
    const handleDDMenuClose = () => {
        setDDAnchorEl(null);
    }
    const { lesion } = props

    const handleViewOptionChange = (event: React.MouseEvent<HTMLElement>,
        newOption: string | null) => {
        setlesionLabelViewset(null)
        setViewOption(newOption)
    }

    // const handelViewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setViewLabel((event.target as HTMLInputElement).value as unknown as number)
    //     setlesionLabelViewset(null)
    // }

    // const handleFetchData = (page: number) => {
    //     if (lesion) {
    //         fetchLesionLabelAPI(lesion.id, viewlabel, page)
    //             .then((response) => {
    //                 if (page > 1 && lesionlabelviewset !== null) {
    //                     setlesionLabelViewset({
    //                         ...lesionlabelviewset,
    //                         data: lesionlabelviewset?.data?.concat(response.data.results),
    //                         page: page,
    //                         count: response.data.count,
    //                     })
    //                 } else {

    //                     setlesionLabelViewset({
    //                         ...lesionlabelviewset,
    //                         data: response.data.results,
    //                         totalpages: response.data.total_pages,
    //                         page: 1,
    //                         count: response.data.count,
    //                         // selframe: response.data.results[0].dataframe,
    //                         sellesion: response.data.results[0],
    //                         pointer: 0
    //                     })
    //                 }

    //             })
    //             .catch((error) => {
    //                 console.log("Error fetching image data: ", error)
    //             })
    //     }
    // }

    // const handleRefreshData = () => {
    //     if (lesion) {
    //         fetchLesionLabelAPI(lesion.id, viewlabel, 1)
    //             .then((response) => {

    //                 setlesionLabelViewset({
    //                     ...lesionlabelviewset,
    //                     data: response.data.results,
    //                     totalpages: response.data.total_pages,
    //                     count: response.data.count,
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log("Error refreshing data: ", error)
    //             })
    //     }
    // }

    // const handleDataListScrollEnd = (event: any) => {
    //     if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 420) {
    //         if (lesionlabelviewset?.page as number + 1 <= (lesionlabelviewset?.totalpages as number)) {
    //             handleFetchData(lesionlabelviewset?.page as number + 1)
    //         }
    //     }
    // }

    // const handleMarkUpdated = () => {

    //     handleRefreshData()
    // }

    // const handleFrameLabelEdited = (frame: ImageDataframe, label: number) => {
    //     // find label item
    //     const labelitem = lesionlabelviewset?.data?.find((element: LesionLabel) => element.dataframe?.id === frame.id)
    //     if (labelitem !== undefined && instanceofLesionLabel(labelitem)) {
    //         editLesionLabelAPI(labelitem.id as string, label)
    //             .then((response) => {
    //                 handleRefreshData()
    //             })
    //             .catch((error) => {
    //                 console.log("Error updating label: ", error)
    //             })
    //     }
    // }

    const handleFrameSelected = (item: ImageDataframe) => {
        // check type of item
        if (item !== undefined && instanceofImageDataframe(item)) {
            // let frameimage = new window.Image()
            // frameimage.src = item.imagedata || '' // Add a default value of an empty string if item.imagedata is undefined
            // frameimage.crossOrigin = 'anonymous'
            // frameimage.onload = () => {
            //     setSelFrameImage(frameimage)
            //     setZoomLevel(1)
            //     const x = (canvaswidth - frameimage.width) / 2
            //     const y = (canvasheight - frameimage.height) / 2
            //     setImageStartPos([x, y])
            // }
            // find pointer location
            if (lesionlabelviewset !== null) {
                const pointer = lesionlabelviewset.data?.findIndex((element: LesionLabel) => element.dataframe?.id === item.id)
                const selitems = lesionlabelviewset.selitems
                if (pointer !== undefined && pointer >= 0) {
                    if (multiSelection) {
                        if (lesionlabelviewset.selitems !== null) {
                            const index = lesionlabelviewset.selitems?.findIndex((element: LesionLabel) => element.dataframe?.id === item.id)
                            if (index === -1) {
                                // get item at gi_index
                                const item: any = lesionlabelviewset.data?.find((element: LesionLabel) => element.dataframe?.id === item.id)
                                selitems?.push(item as LesionLabel)
                            } else if (index !== undefined) {
                                selitems?.splice(index, 1)
                            }

                        }
                    }

                    setlesionLabelViewset({
                        ...lesionlabelviewset,
                        // selframe: item.dataframe,
                        selitem: lesionlabelviewset?.data?.[pointer] as LesionLabel,
                        pointer: pointer
                    })
                }
            }

        }
    }

    const nextFrame = () => {
        const pointer = lesionlabelviewset?.pointer as number
        if (pointer + 1 < (lesionlabelviewset?.data?.length as number)) {
            const nextframe = lesionlabelviewset?.data?.[pointer + 1]
            handleFrameSelected(nextframe?.dataframe as ImageDataframe)
        }
    }

    const prevFrame = () => {
        const pointer = lesionlabelviewset?.pointer as number
        if (pointer - 1 >= 0) {
            const prevframe = lesionlabelviewset?.data?.[pointer - 1]
            handleFrameSelected(prevframe?.dataframe as ImageDataframe)
        }
    }

    const handleLesionLabelEdited = (lesionlabel: LesionLabel) => {
        if (lesionlabelviewset !== null) {
            const newdata = lesionlabelviewset.data?.map((element: LesionLabel) => {
                if (element.id === lesionlabel.id) {
                    return lesionlabel
                } else {
                    return element
                }
            })
            setlesionLabelViewset({
                ...lesionlabelviewset,
                data: newdata,
                selitem: lesionlabel
            })
        }
    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset | LesionLabelViewset | GIOrganLabelViewset) => {
        console.log("Viewset changed: ", lesionlabelviewset)
        setlesionLabelViewset(viewset as LesionLabelViewset)
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
    }

    // const zoomIn = () => {
    //     setZoomLevel(zoomLevel + 0.1)
    //     const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) * 1.1
    //     const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) * 1.1
    //     setImageStartPos([new_x, new_y])
    // }

    // const zoomOut = () => {
    //     setZoomLevel(zoomLevel - 0.1)
    //     const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) / 1.1
    //     const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) / 1.1
    //     setImageStartPos([new_x, new_y])
    // }

    // const dragStart = () => {
    //     setIsDragging(true)
    // }

    // const dragEnd = (event: any) => {
    //     setImageStartPos([event.target.x(), event.target.y()])
    //     setIsDragging(false)
    // }

    // const resetImageView = () => {
    //     setZoomLevel(1)
    //     const x = (canvaswidth - selFrameImage.width) / 2
    //     const y = (canvasheight - selFrameImage.height) / 2
    //     setImageStartPos([x, y])
    // }

    // React.useEffect(() => {
    //     handleFetchData(1)
    // }, [viewlabel])


    return (
        <>
            <Stack direction='row' spacing={0}>
                <Box
                    sx={{
                        width: '50%'
                    }}
                >
                    {viewoption === 'thumb' ? (
                        <Box
                            sx={{
                                width: 500,
                                height: 500,
                            }}>
                            <ImageDataframeList
                                datatype='lesion'
                                dataid={lesion?.id as string}
                                viewset={lesionlabelviewset as GIOrganLabelViewset}
                                start={lesion?.dataset?.start_ts as number}
                                stop={lesion?.dataset?.stop_ts as number}
                                showLabel={true}
                                multiSelection={multiSelection}
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />

                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: 500,
                            }}>
                            <Heatmap
                                datatype='organ'
                                dataid={lesion?.id as string}
                                viewset={lesionlabelviewset as LesionLabelViewset}
                                width={500}
                                height={490}
                                rows={20}
                                cols={20}
                                margin={
                                    {
                                        top: 10,
                                        right: 5,
                                        bottom: 10,
                                        left: 5
                                    }
                                }
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />
                        </Box>
                    )}
                    <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>
                        <ToggleButtonGroup
                            value={viewoption}
                            exclusive
                            onChange={handleViewOptionChange}
                            size='small'
                            color='primary'

                        >
                            <ToggleButton value='thumb' aria-label='thumb'>
                                <ViewModuleIcon />
                            </ToggleButton>
                            <ToggleButton value='heatmap' aria-label='heatmap'>
                                <ViewCompactIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControlLabel
                            label="Enable Multi-Select"
                            control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                        />
                        <div>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleDDMenuClick}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Mark as
                            </Button>
                            <DropDownMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={ddanchorEl}
                                open={open}
                                onClose={handleDDMenuClose}
                            >
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Unknown
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Esophagus
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Stomach
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Small Bowel
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Colon
                                </MenuItem>
                            </DropDownMenu>
                        </div>
                        <ButtonGroup
                            aria-label='tool-buttons'
                            disabled={lesionlabelviewset?.selitem === null || lesionlabelviewset?.selitem === undefined}
                        >
                            <IconButton aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="add-in-report">
                                <NoteAddIcon />
                            </IconButton>
                        </ButtonGroup>
                    </Stack>

                </Box>
                <Box
                    sx={{
                        width: '50%',
                        ml: 4
                    }}
                >
                    <Grid container direction='column' spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item xs={12}>
                            <LesionLabelImageView
                                image={lesionlabelviewset?.selitem?.dataframe?.imagedata as string}
                                canvaswidth={500}
                                canvasheight={320} />
                            {/* <Stage width={canvaswidth} height={canvasheight}>
                                <Layer>
                                    <Image
                                        image={selFrameImage}
                                        x={imageStartPos[0]}
                                        y={imageStartPos[1]}
                                        scaleX={zoomLevel}
                                        scaleY={zoomLevel}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={dragStart}
                                        onDragEnd={dragEnd}
                                    />
                                </Layer>

                            </Stage>
                            <CustomFab
                                aria-label="zoomin"
                                onClick={() => { zoomIn() }}
                                disabled={zoomLevel < 2 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 96
                                }}>
                                <ZoomInIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="zoomout"
                                onClick={() => { zoomOut() }}
                                disabled={zoomLevel > 1 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 144
                                }}>
                                <ZoomOutIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="reset"
                                onClick={resetImageView}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 192
                                }}>
                                <RestartAltIcon fontSize="large" />
                            </CustomFab> */}

                        </Grid>
                        <Grid item xs={12}>
                            <IconButton edge='end' aria-label='First frame'>
                                <SkipPreviousIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Previous frame' onClick={prevFrame}>
                                <NavigateBeforeIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Next frame' onClick={nextFrame}>
                                <NavigateNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Last frame'>
                                <SkipNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <EditLesionLabel lesionlabel={lesionlabelviewset?.selitem as LesionLabel} onEdit={handleLesionLabelEdited} />

                        </Grid>

                    </Grid>

                </Box>
            </Stack>
        </>
    )
}