import axios from "axios"
import { client_id, client_secret, rest_endpoint } from "../../settings"


export const signout = (token: string) => {
    let url = `${rest_endpoint}o/revoke_token/`

    return axios.post(url, `token=${token}&token_type_hint=access_token&client_id=${client_id}&client_secret=${client_secret}`)
}

export const fetchProfilesAPI = (role: number) => {
    let url = `${rest_endpoint}profiles/?role=${role}`
    return axios.get<any>(url)
}