import * as React from 'react';
import { FileSource } from '../../../../types';
import { deleteFileSourceAPI, fetchFilesListAPI, uploadAPI } from '../../../../reducers/func/dataManagementFunc';
import { Backdrop, Box, Button, Fade, IconButton, LinearProgress, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteIcon from '@mui/icons-material/Delete';
import { readableFileSize } from '../../../../helpers';

interface ITask {
    task?: string | null
}

export const TaskFilesTable: React.FC<ITask> = (props: ITask) => {
    const [taskfiles, setTaskFiles] = React.useState<FileSource[] | null>(null)
    const devicefileRef = React.useRef<any>(null)
    const { task } = props
    const [showuploadingmsg, setShowUploadingMsg] = React.useState(false)
    const [currentuploadfile, setCurrentUploadFile] = React.useState<File | null>(null)
    const [uploadprogress, setUploadProgress] = React.useState(0)
    const [filelistUpdated, setFileListUpdated] = React.useState(false)
    const [showfiledeleteconfirmation, setShowFileDeleteConfirmation] = React.useState(false)
    const [selFS, setSelFS] = React.useState<FileSource | null>(null)

    const onFileDeleteConfirmationOpened = (fs: FileSource) => {
        setSelFS(fs)
        setShowFileDeleteConfirmation(true)
    }
    const onFileDeleteConfirmationClosed = () => {
        setSelFS(null)
        setShowFileDeleteConfirmation(false)
    }

    const handleAddFile = () => {
        if (devicefileRef.current !== null) {
            devicefileRef.current.click()
        }
    }

    const handleSelectDeviceFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        onDeviceFileSelected(event.target.files as FileList)
    }

    const handleUploadingFile = async (task: number, f: File) => {
        try {
            setShowUploadingMsg(true)
            setUploadProgress(0)
            setCurrentUploadFile(f)
            // set chunk size to 10 MB
            const chunkSize = 10000000
            const num_chunks = Math.ceil(f.size / chunkSize)
            console.log("Number of chunks: ", num_chunks)
            let start = 0
            let end = Math.min(start + chunkSize, f.size)
            for (let i = 0; i < num_chunks; i++) {
                let last: boolean = (i === (num_chunks - 1))
                let chunk: Blob = f.slice(start, end)
                console.log("File name: ", f.name)
                console.log("Uploading chunk: ", i)
                console.log("Chunk size: ", chunk.size)
                console.log("Start: ", start)
                console.log("End: ", end)
                console.log("Last chunk: ", last)
                start = end
                end = Math.min(start + chunkSize, f.size)

                if (last) {
                    console.log("last chunk to be submitted.")
                }

                await uploadAPI(task, chunk, f.name, last, (event: any) => {
                    setUploadProgress(Math.round(((event.loaded / event.total + i) * (chunkSize)) * 100 / f.size))
                })
            }
            setCurrentUploadFile(null)
            setShowUploadingMsg(false)
        } catch (e) {
            console.log("Error uploading file: ", e)
            setCurrentUploadFile(null)
            setShowUploadingMsg(false)
        }
    }

    const onDeviceFileSelected = (fl: FileList) => {
        const f_arr = Array.from(fl)
        f_arr.forEach(async (f: File) => {
            await handleUploadingFile(task as unknown as number, f)
        })
        setFileListUpdated(true)
    }

    const handleDeleteFile = () => {
        deleteFileSourceAPI(selFS?.id as string)
            .then((response) => {
                console.log("File deleted: ", response.data)
                setFileListUpdated(true)
                setShowFileDeleteConfirmation(false)
            })
            .catch((error) => {
                console.log("Error deleting file: ", error)
            })
    }

    React.useEffect(() => {
        // load files list on component mounted
        if (taskfiles === null || filelistUpdated) {
            fetchFilesListAPI(task as string)
                .then((response) => {
                    setTaskFiles(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching files: ", error)
                })
        }

    }, [filelistUpdated, task, taskfiles]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Box

                    sx={{
                        width: '100%',
                        height: 36,
                        backgroundColor: 'white',
                        pt: 1
                    }}
                >
                    <Toolbar
                        variant='dense'
                        sx={{
                            minHeight: 36,
                            borderBottom: '2px solid #212121',
                        }}
                    >
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Typography variant='subtitle1'>Files</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: 'flex' }}>
                            <Button variant='contained' startIcon={<AddIcon />}
                                onClick={handleAddFile}
                                disableElevation
                            >
                                Add File
                                <input type="file" id="devicefile"
                                    ref={devicefileRef}
                                    style={{ display: 'none' }}
                                    accept="application/x-tar,application/zip,application/x-7z-compressed"
                                    onChange={handleSelectDeviceFile} />
                            </Button>
                        </Box>

                    </Toolbar>

                </Box>

                <TableContainer sx={{ mt: 0.5, p: 0, width: '100%', maxHeight: '33vh' }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {taskfiles && taskfiles?.map((f: FileSource) => (
                                <TableRow key={f.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component={'th'} scope='row'>
                                        {f.sourcename}
                                    </TableCell>
                                    <TableCell>{readableFileSize(f.size)}</TableCell>
                                    <TableCell>
                                        <IconButton edge='end' aria-label='preview dataset' >
                                            <DownloadForOfflineIcon sx={{ fontSize: 22 }} />
                                        </IconButton>
                                        <IconButton edge='end' aria-label='remove dataset' onClick={() => onFileDeleteConfirmationOpened(f)} >
                                            <DeleteIcon sx={{ fontSize: 22 }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
            <Backdrop
                open={showuploadingmsg}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Paper
                    square={true}
                    elevation={0}
                    sx={{
                        width: 300,
                        height: 200,
                        padding: 0
                    }}
                >
                    <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                        <Box sx={{ width: '90%' }}>
                            <LinearProgress variant='determinate' value={uploadprogress} />
                        </Box>
                        <Typography variant='caption'>Uploading {currentuploadfile?.name}</Typography>

                    </Stack>
                </Paper>
            </Backdrop>
            <Modal
                aria-labelledby="delete-ds-modal-title"
                aria-describedby="delete-ds-modal-description"
                open={showfiledeleteconfirmation}
                onClose={onFileDeleteConfirmationClosed}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showfiledeleteconfirmation}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="delete-ds-modal-title" variant='subtitle1'>Delete File</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={onFileDeleteConfirmationClosed}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '200px',
                                    p: 1,
                                    overflow: 'auto'
                                }}
                            >
                                <Typography variant='body1'>Are you sure you want to delete this file?</Typography>
                            </Box>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={onFileDeleteConfirmationClosed}
                                    sx={{
                                        ml: 1,
                                        mr: 1
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={handleDeleteFile}
                                    sx={{
                                        ml: 1,
                                        mr: 1
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}