enum USER_AT {
    USER_LOGIN = 'USER_LOGIN',
    USER_LOGIN_FULFILLED = 'USER_LOGIN_FULFILLED',
    USER_LOGOUT = 'USER_LOGOUT',
    USER_LOGOUT_FULFILLED = 'USER_LOGOUT_FULFILLED',
    USER_SIGNUP = 'USER_SIGNUP',
    USER_SIGNUP_FULFILLED = 'USER_SIGNUP_FULFILLED',
    USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED',
    USER_SET_CURRENT_URI = 'USER_SET_CURRENT_URI'
}

export default USER_AT