import { Box, Container } from '@mui/material'
import * as React from 'react'
import { useAppSelector } from '../../hooks/AppReducerHooks'
import { Footer } from './Footer'
import { MainAppbar } from './MainAppBar'

type IProps = {
    children: React.ReactNode
}

export const LayOut: React.FC<IProps> = ({children}:IProps): JSX.Element => {
    const userInfo = useAppSelector((state) => state.user)
    const now = (new Date()).getTime() / 1000
    return (
        <>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {userInfo.isLoggedIn && userInfo.tokenExpireOn > now && (
            <MainAppbar />
            )}
            <Container 
                maxWidth={false}
                sx={{
                    height: '100vh',
                    pt: '48px',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important'
                    // maxWidth: {
                    //     md: "md",
                    //     lg: "lg"
                    // }
                }}>
                {children}
            </Container>
        </Box>
        <Footer />
        </>
    )
}