import * as React from 'react'
import { connect } from 'react-redux'
// import { returntypeof } from 'react-redux-typescript'
import { Redirect, Route, Switch } from 'react-router-dom'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { setCurrentUri } from '../actions/userActions'
import CapsuleEndoscopyTaskManager from './CapsuleEndoscopyTaskManager'
import CapsuleEndoscopyTaskDetail from './CapsuleEndoscopyTaskDetail'

const now = (new Date()).getTime() / 1000
const userIsAuthenticated = connectedRouterRedirect<any, any>({
    // The url to redirect user to if they fail
    redirectPath: '/signin',
    // Determine if the user is authenticated or not
    authenticatedSelector: state => state.user.isLoggedIn && (state.user.tokenExpireOn > now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
})

const ProtectedTaskManager = userIsAuthenticated(CapsuleEndoscopyTaskManager)
const ProtectedTaskDetail = userIsAuthenticated(CapsuleEndoscopyTaskDetail)
class Component extends React.Component<any, {}> {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        const { setCurrentUri } = this.props
        setCurrentUri('/capsuleendoscopy')
    }

    render() {
        return (
            <Switch>
                <Redirect exact path="/capsuleendoscopy" to="/capsuleendoscopy/manager" />
                <Route exact path="/capsuleendoscopy/manager" component={ProtectedTaskManager} />
                <Route exact path="/capsuleendoscopy/task/:taskid" component={ProtectedTaskDetail} />
            </Switch>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Component);
