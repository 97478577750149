import { Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material'
import * as React from 'react'
import { toAbsoluteUrl } from '../../../../helpers'
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FolderIcon from '@mui/icons-material/Folder';

interface IDataSrcMenuPopOver {
    onDeviceFileSelected: (fl: FileList) => void 
    onDeviceDirSelected: (fl: FileList) => void
}

export const DataSrcMenuPopOver: React.FC<IDataSrcMenuPopOver> = (props: IDataSrcMenuPopOver) => {
    const { onDeviceDirSelected, onDeviceFileSelected } = props
    const devicefileRef = React.useRef<any>(null)
    const devicedirRef = React.useRef<any>(null)

    const handleOpenDeviceFileBrowser = () => {
        if (devicefileRef.current !== null) {
          devicefileRef.current.click()
        }
    }

    const handleSelectDeviceFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        onDeviceFileSelected(event.target.files as FileList)
    }

    const handleOpenDeviceDirBrowser = () => {
        if (devicedirRef.current !== null) {
          devicedirRef.current.click()
        }
    }

    const handleSelectDeviceDir = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        onDeviceDirSelected(event.target.files as FileList)
    }

    React.useEffect(() => {
        if (devicedirRef.current !== null) {
            devicedirRef.current.setAttribute("directory", "");
            devicedirRef.current.setAttribute("webkitdirectory", "");
        }
        
      }, [devicedirRef])

    return (
        
        <List>
            <ListItemButton onClick={handleOpenDeviceFileBrowser}>
                <ListItemIcon>
                    <FolderZipIcon />
                </ListItemIcon>
                <ListItemText primary="From archive file on your device" />
                <input type="file" id="devicefile" 
                    ref={devicefileRef} 
                    style={{display: 'none'}} 
                    accept="application/x-tar,application/zip,application/x-7z-compressed"
                    onChange={handleSelectDeviceFile} />
            </ListItemButton>
            <ListItemButton onClick={handleOpenDeviceDirBrowser}>
                <ListItemIcon>
                    <FolderIcon />
                </ListItemIcon>
                <ListItemText primary="From archive directory on your device" />
                <input type="file" id="devicedir" 
                    ref={devicedirRef} 
                    style={{display: 'none'}} 
                    onChange={handleSelectDeviceDir} />
            </ListItemButton>
        </List>

    )
}