import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist";
import { createLogger } from "redux-logger"
import createSagaMiddleware from "redux-saga"
import rootReducer from ".";
import { rootSaga } from ".";
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
    key: 'gutdx-analytics',
    storage
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const logger = createLogger()
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMidleware) => getDefaultMidleware().concat(sagaMiddleware, logger),
})

sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
