import { Box, Button, Divider, Paper, Stack, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
// import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { setCurrentUri } from '../actions/userActions'
import AddIcon from '@mui/icons-material/Add';
// import SearchIcon from '@mui/icons-material/Search';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { toAbsoluteUrl } from '../helpers'
import { CapsuleEndoscopyList } from '../layouts/components/widgets/lists/CapsuleEndoscopyList'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AddCapsuleEndoscopyTask } from '../layouts/components/widgets/forms/AddCapsuleEndoscopyTask'
import { CapsuleEndoscopyTaskContext } from '../providers/CapsuleEndoscopyTaskContext'
// import { fetchTasksAPI } from '../reducers/func/dataManagementFunc'
// import { Task } from '../types'

/* const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '150px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '200px',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: 200,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#212121',
    },
  })); */

class Component extends React.Component<any, {
    adding: boolean,
    newtaskpanelwidth?: number,
    draggingNewTaskSeparator: boolean,
    xpositionNewTaskSeparator?: number,
    reloadtasks: boolean
}> {
    addpanelRef: React.RefObject<any>

    constructor(props: any) {
        super(props)
        this.handleAddingTask = this.handleAddingTask.bind(this)
        this.handleCancelAddingTask = this.handleCancelAddingTask.bind(this)
        this.handleMouseDownOnNewTaskDivider = this.handleMouseDownOnNewTaskDivider.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)
        this.handleReloadTasks = this.handleReloadTasks.bind(this)
        this.state = {
            adding: false,
            newtaskpanelwidth: 400,
            draggingNewTaskSeparator: false,
            reloadtasks: false
        }
        this.addpanelRef = React.createRef()
    }

    handleAddingTask() {
        this.setState({ ...this.state, adding: true })
    }

    handleCancelAddingTask() {
        this.setState({ ...this.state, adding: false })
    }

    handleMouseDownOnNewTaskDivider(e: React.MouseEvent) {
        this.setState({
            ...this.state,
            xpositionNewTaskSeparator: e.clientX,
            draggingNewTaskSeparator: true
        })
    }

    handleMouseUp() {
        if (this.state.draggingNewTaskSeparator) {
            this.setState({
                ...this.state,
                draggingNewTaskSeparator: false
            })
        }
    }

    handleMouseMove(e: MouseEvent) {
        if (this.state.draggingNewTaskSeparator && this.state.newtaskpanelwidth && this.state.xpositionNewTaskSeparator) {
            const newwidth = this.state.newtaskpanelwidth - e.clientX + this.state.xpositionNewTaskSeparator
            if (newwidth > 400 && newwidth < 600) {
                this.setState({
                    ...this.state,
                    xpositionNewTaskSeparator: e.clientX,
                    newtaskpanelwidth: this.state.newtaskpanelwidth - e.clientX + this.state.xpositionNewTaskSeparator
                })
            }
        }

    }

    handleReloadTasks(reload: boolean) {
        this.setState({ ...this.state, reloadtasks: reload })
    }

    componentDidMount() {
        document.addEventListener('mousemove', (e) => this.handleMouseMove(e))
        document.addEventListener('mouseup', (e) => this.handleMouseUp())
    }

    componentDidUpdate() {

    }

    render() {
        return (
            <CapsuleEndoscopyTaskContext.Provider value={{
                state: { ...this.state },
                dispatch: {},
                refs: {}
            }}>

                <Box
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {/* <Stack spacing={0}> */}
                    <Box

                        sx={{
                            width: '100%',
                            height: 42,
                            backgroundColor: 'white'
                        }}
                    >
                        <Toolbar
                            variant='dense'
                            sx={{
                                minHeight: 42
                            }}
                        >
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <Typography variant='subtitle1'>Capsule Endoscopy Task Manager</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                <Button variant='contained' startIcon={<AddIcon />}
                                    disabled={this.state.adding}
                                    onClick={this.handleAddingTask}
                                >
                                    Add Task
                                </Button>
                            </Box>

                        </Toolbar>

                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            height: 'calc(100% - 77px)'
                        }}
                    >
                        <Stack direction='row' spacing={0}
                            sx={{
                                height: '100%'
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexGrow={1}
                                flexShrink={1}
                                paddingTop="10px"
                                paddingBottom="10px"
                                paddingLeft="12px"
                                paddingRight={this.state.adding ? "0px" : "12px"}

                            >

                                <CapsuleEndoscopyList onTaskLoaded={this.handleReloadTasks} />


                            </Box>


                            {this.state.adding && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexGrow={0}
                                        padding="0px"
                                    >
                                        <Divider
                                            orientation='vertical'
                                            variant='middle'
                                            onMouseDown={this.handleMouseDownOnNewTaskDivider}
                                            sx={{ cursor: 'col-resize', height: 10, '& .MuiDivider-wrapperVertical': { padding: 0 } }}>
                                            <DragIndicatorIcon sx={{ fontSize: '12px' }} />
                                        </Divider>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexGrow={0}
                                        paddingTop="10px"
                                        paddingBottom="10px"
                                        paddingLeft="0px"
                                        paddingRight="0px"
                                        sx={{
                                            width: `${this.state.newtaskpanelwidth}px`
                                        }}
                                        ref={this.addpanelRef}
                                    >

                                        <AddCapsuleEndoscopyTask onClose={this.handleCancelAddingTask} onSaveComplete={this.handleReloadTasks} />
                                    </Box>
                                </>
                            )}

                            {/* <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="42px"
                    flexGrow={0}
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="12px"
                    paddingRight="12px"
                    
                >
                    <Paper
                        square={true}
                        elevation={0}
                        sx = {{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        
                    </Paper>
                                </Box> */}
                        </Stack>
                    </Box>
                    {/* </Stack> */}
                </Box>



            </CapsuleEndoscopyTaskContext.Provider>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Component);