import { UserActionObject } from '../types'
import USER_AT from '../types/UserActionTypes'

export const login = (decodedIdToken: any, accessToken: string): UserActionObject =>
({
    type: USER_AT.USER_LOGIN,
    decodedIdToken,
    accessToken
})

export const loginFulfilled = (id: string, uid: string, name: string, email: string, avatar: string, token: string, expiration: number, role: number): UserActionObject =>
({
    type: USER_AT.USER_LOGIN_FULFILLED,
    id,
    uid,
    name,
    email,
    avatar,
    token, 
    expiration,
    role
})

export const logout = (token: string): UserActionObject => 
    ({
        type: USER_AT.USER_LOGOUT,
        token
    })

export const logoutFulfilled = (): UserActionObject =>
({
    type: USER_AT.USER_LOGOUT_FULFILLED
})

export const setCurrentUri = (currentUri: string): UserActionObject => 
({
    type: USER_AT.USER_SET_CURRENT_URI,
    currentUri
})