import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, MenuProps, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles';
import { GIOrgan, GIOrganLabel, GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, LesionLabelViewset, instanceofGIOrganLabel, instanceofImageDataframe } from '../../../../types'
import { ImageDataframeList } from '../lists/ImageDataframeList'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ClearIcon from '@mui/icons-material/Clear';
import { LesionLabelImageView } from './LesionLabelImageView'
import { EditOrganLabel } from '../forms/EditOrganLabel'
import { Heatmap } from '../../charts/Heatmap'
import { Tooltip } from 'rsuite';
import { markorganforexportAPI, unmarkorganforexportAPI } from '../../../../reducers/func/dataManagementFunc';
import { set } from 'lodash';

interface IGIOrganLabelView {
    gi: GIOrgan | null
}

const DropDownMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const GIOrganLabelView: React.FC<IGIOrganLabelView> = (props: IGIOrganLabelView) => {
    // const [viewrange, setViewRange] = React.useState<number[]>([])
    // const [viewlabel, setViewLabel] = React.useState<number | null>(null)
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [giorganlabelviewset, setGIOrganLabelViewset] = React.useState<GIOrganLabelViewset | null>(null)
    const [viewoption, setViewOption] = React.useState<string | null>('thumb')
    const [ddanchorEl, setDDAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(ddanchorEl);
    const handleDDMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setDDAnchorEl(event.currentTarget);
    }
    const handleDDMenuClose = () => {
        setDDAnchorEl(null);
    }
    const { gi } = props

    const handleViewOptionChange = (event: React.MouseEvent<HTMLElement>,
        newOption: string | null) => {
        setGIOrganLabelViewset(null)
        setViewOption(newOption)
    }

    // const handleFetchData = (page: number) => {
    //     if (gi) {
    //         fetchGIOrganLabelAPI(gi.id, viewlabel, page, 12)
    //             .then((response) => {
    //                 if (page > 1 && giorganlabelviewset !== null) {
    //                     setGIOrganLabelViewset({
    //                         ...giorganlabelviewset,
    //                         data: giorganlabelviewset?.data?.concat(response.data.results),
    //                         page: page,
    //                         count: response.data.count,
    //                     })
    //                 } else {
    //                     setGIOrganLabelViewset({
    //                         //...giorganlabelviewset,
    //                         data: response.data.results,
    //                         totalpages: response.data.total_pages,
    //                         page: 1,
    //                         count: response.data.count,
    //                         // selframe: response.data.results[0].dataframe,
    //                         selorgan: response.data.results[0],
    //                         pointer: 0
    //                     })
    //                 }

    //             })
    //             .catch((error) => {
    //                 console.log("Error fetching image data: ", error)
    //             })
    //     }
    // }

    // const handleRefreshData = () => {
    //     if (gi) {
    //         fetchGIOrganLabelAPI(gi.id, viewlabel, 1, 12)
    //             .then((response) => {

    //                 setGIOrganLabelViewset({
    //                     ...giorganlabelviewset,
    //                     data: response.data.results,
    //                     totalpages: response.data.total_pages,
    //                     count: response.data.count,
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log("Error refreshing data: ", error)
    //             })
    //     }
    // }

    // const handleDataListScrollEnd = (event: any) => {
    //     if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 420) {
    //         if (giorganlabelviewset?.page as number + 1 <= (giorganlabelviewset?.totalpages as number)) {
    //             handleFetchData(giorganlabelviewset?.page as number + 1)
    //         }
    //     }
    // }

    // const handleMarkUpdated = () => {

    //     handleRefreshData()
    // }

    // const handleFrameLabelEdited = (frame: ImageDataframe, label: number) => {
    //     // find label item
    //     const labelitem = giorganlabelviewset?.data?.find((element: GIOrganLabel) => element.dataframe?.id === frame.id)
    //     if (labelitem !== undefined && instanceofGIOrganLabel(labelitem)) {
    //         editLabelAPI(labelitem.id as string, label)
    //             .then((response) => {
    //                 handleRefreshData()
    //             })
    //             .catch((error) => {
    //                 console.log("Error updating label: ", error)
    //             })
    //     }
    // }

    const handleFrameSelected = (item: ImageDataframe) => {
        // check type of item
        if (item !== undefined && instanceofImageDataframe(item)) {
            // find pointer location
            if (giorganlabelviewset !== null) {
                const pointer = giorganlabelviewset.data?.findIndex((element: GIOrganLabel) => element.dataframe?.id === item.id)
                const selitems = giorganlabelviewset.selitems
                if (pointer !== undefined && pointer >= 0) {
                    if (multiSelection) {
                        if (giorganlabelviewset.selitems !== null) {
                            const gi_index = giorganlabelviewset.selitems?.findIndex((element: GIOrganLabel) => element.dataframe?.id === item.id)
                            if (gi_index === -1) {
                                // get item at gi_index
                                const gi_item = giorganlabelviewset.data?.find((element: GIOrganLabel) => element.dataframe?.id === item.id)
                                selitems?.push(gi_item as GIOrganLabel)
                            } else if (gi_index !== undefined) {
                                selitems?.splice(gi_index, 1)
                            }

                        }
                    }

                    setGIOrganLabelViewset({
                        ...giorganlabelviewset,
                        selitem: giorganlabelviewset.data?.[pointer as number] as GIOrganLabel,
                        selitems: selitems,
                        pointer: pointer
                    })
                }
            }

        }
    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset | GIOrganLabelViewset | LesionLabelViewset) => {
        console.log("Viewset changed: ", giorganlabelviewset)
        setGIOrganLabelViewset(viewset as GIOrganLabelViewset)
    }

    const nextFrame = () => {
        const pointer = giorganlabelviewset?.pointer as number
        if (pointer + 1 < (giorganlabelviewset?.data?.length as number)) {
            const nextframe = giorganlabelviewset?.data?.[pointer + 1]
            handleFrameSelected(nextframe?.dataframe as ImageDataframe)
        }
    }

    const prevFrame = () => {
        const pointer = giorganlabelviewset?.pointer as number
        if (pointer - 1 >= 0) {
            const prevframe = giorganlabelviewset?.data?.[pointer - 1]
            handleFrameSelected(prevframe?.dataframe as ImageDataframe)
        }
    }

    const handleOrganLabelEdited = (organlabel: GIOrganLabel) => {
        if (giorganlabelviewset !== null) {
            const newdata = giorganlabelviewset.data?.map((element: GIOrganLabel) => {
                if (element.id === organlabel.id) {
                    return organlabel
                } else {
                    return element
                }
            })
            setGIOrganLabelViewset({
                ...giorganlabelviewset,
                data: newdata,
                selitem: organlabel
            })
        }
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
    }

    const markSelectionsForExport = () => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                markorganforexportAPI(gi?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        setGIOrganLabelViewset(null)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    const unmarkSelectionsForExport = () => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                unmarkorganforexportAPI(gi?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        setGIOrganLabelViewset(null)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    // React.useEffect(() => {
    //     if (gi && gi.dataset && viewrange.length <= 0) {
    //         setViewRange([gi.dataset.start_ts, gi.dataset.stop_ts])
    //     }
    // }, [giorganlabelviewset])

    // React.useEffect(() => {
    //     handleFetchData(1)
    // }, [viewrange, viewlabel])




    return (
        <>
            <Stack direction='row' spacing={0}>
                <Box
                    sx={{
                        width: '50%',
                        height: 500,
                    }}
                >


                    {viewoption === 'thumb' ? (
                        <Box
                            sx={{
                                width: 500,
                                height: 500,
                            }}>
                            <ImageDataframeList
                                datatype='organ'
                                dataid={gi?.id as string}
                                viewset={giorganlabelviewset as GIOrganLabelViewset}
                                start={gi?.dataset?.start_ts as number}
                                stop={gi?.dataset?.stop_ts as number}
                                showLabel={true}
                                multiSelection={multiSelection}
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />

                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: 500,
                            }}>
                            <Heatmap
                                datatype='organ'
                                dataid={gi?.id as string}
                                viewset={giorganlabelviewset as GIOrganLabelViewset}
                                width={500}
                                height={490}
                                rows={20}
                                cols={20}
                                margin={
                                    {
                                        top: 10,
                                        right: 5,
                                        bottom: 10,
                                        left: 5
                                    }
                                }
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />
                        </Box>
                    )}
                    <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>
                        <ToggleButtonGroup
                            value={viewoption}
                            exclusive
                            onChange={handleViewOptionChange}
                            size='small'
                            color='primary'

                        >
                            <ToggleButton value='thumb' aria-label='thumb'>
                                <ViewModuleIcon />
                            </ToggleButton>
                            <ToggleButton value='heatmap' aria-label='heatmap'>
                                <ViewCompactIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControlLabel
                            label="Enable Multi-Select"
                            control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                        />
                        <div>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleDDMenuClick}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Mark as
                            </Button>
                            <DropDownMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={ddanchorEl}
                                open={open}
                                onClose={handleDDMenuClose}
                            >
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Unknown
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Esophagus
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Stomach
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Small Bowel
                                </MenuItem>
                                <MenuItem onClick={handleDDMenuClose} disableRipple>
                                    Colon
                                </MenuItem>
                            </DropDownMenu>
                        </div>
                        <ButtonGroup
                            aria-label='tool-buttons'
                            disabled={giorganlabelviewset?.selitems === null || giorganlabelviewset?.selitems === undefined || giorganlabelviewset?.selitems?.length === 0}
                        >
                            <IconButton aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="add-in-report"
                                onClick={markSelectionsForExport}
                            >
                                <NoteAddIcon />
                            </IconButton>
                            <IconButton aria-label="remove-from-report"
                                onClick={unmarkSelectionsForExport}
                            >
                                <ClearIcon />
                            </IconButton>

                        </ButtonGroup>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        ml: 4
                    }}
                >
                    <Grid container direction='column' spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item xs={12}>
                            <LesionLabelImageView
                                image={giorganlabelviewset?.selitem?.dataframe?.imagedata}
                                canvaswidth={500}
                                canvasheight={320} />
                            {/* <Stage width={500} height={320}>
                                <Layer>
                                    <Image
                                        image={selFrameImage}
                                        x={imageStartPos[0]}
                                        y={imageStartPos[1]}
                                        scaleX={zoomLevel}
                                        scaleY={zoomLevel}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={dragStart}
                                        onDragEnd={dragEnd}
                                    />
                                </Layer>

                            </Stage>
                            <CustomFab
                                aria-label="zoomin"
                                onClick={() => { zoomIn() }}
                                disabled={zoomLevel < 2 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 96
                                }}>
                                <ZoomInIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="zoomout"
                                onClick={() => { zoomOut() }}
                                disabled={zoomLevel > 1 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 144
                                }}>
                                <ZoomOutIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="reset"
                                onClick={resetImageView}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 192
                                }}>
                                <RestartAltIcon fontSize="large" />
                            </CustomFab> */}

                        </Grid>
                        <Grid item xs={12}>
                            <IconButton edge='end' aria-label='First frame'>
                                <SkipPreviousIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Previous frame' onClick={prevFrame}>
                                <NavigateBeforeIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Next frame' onClick={nextFrame}>
                                <NavigateNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Last frame'>
                                <SkipNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <EditOrganLabel organlabel={giorganlabelviewset?.selitem as GIOrganLabel} onEdit={handleOrganLabelEdited} />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </>
    )
}