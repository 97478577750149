import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import { LayoutSplashScreen } from "../layouts"
import CapsuleEndoscopyBase from "./CapsuleEndoscopyBase"
import Dashboard from "./Dashboard"
import Signout from "./Signout"

const now = (new Date()).getTime() / 1000
const userIsAuthenticated = connectedRouterRedirect<any, any>({
    // The url to redirect user to if they fail
    redirectPath: '/signin',
    // Determine if the user is authenticated or not
    authenticatedSelector: state => state.user.isLoggedIn && (state.user.tokenExpireOn > now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
})

const ProtectedDashboard = userIsAuthenticated(Dashboard)
const ProtectedSignOut = userIsAuthenticated(Signout)
const ProtectedCapsuleEndoscopy = userIsAuthenticated(CapsuleEndoscopyBase)

class Component extends React.Component {
    render() {
        return (
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {
                        <Redirect exact from="/" to="/dashboard" />
                    }
                    <Route exact path="/dashboard" component={ProtectedDashboard} />
                    <Route path="/capsuleendoscopy" component={ProtectedCapsuleEndoscopy} />
                    <Route exact path="/signout" component={ProtectedSignOut} />
                </Switch>
            </Suspense>
        )
    }
}

export default Component