import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material'
import * as React from 'react'
import { createDatasetAPI, fetchFilesListAPI } from '../../../../reducers/func/dataManagementFunc'
import { FileSource } from '../../../../types'

interface INewDataset {
    task?: string
    onSuccess: () => void
}

export const NewDatasetForm: React.FC<INewDataset> = (props: INewDataset) => {
    const [fileslist, setFileslist] = React.useState<FileSource[] | null>(null)
    const [filesrc, setFileSrc] = React.useState<string | null>(null)
    const { task, onSuccess } = props

    const handleFileSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileSrc((event.target as HTMLInputElement).value as string);
    }

    const handleSubmit = () => {
        createDatasetAPI(task as string, filesrc as string)
        .then((response) => {
            onSuccess()
        })
        .catch((error) => {
            console.log("Error creating dataset: ", error)
        })
    }

    React.useEffect(() => {
        if (task && fileslist == null) {
            // fetch files list
            fetchFilesListAPI(task)
            .then((response) => {
                setFileslist(response.data)
            })
            .catch((error) => {
                console.log("Error fetching files: ", error)
            })
        }
    }, [])

    return (
        <>
        <Stack spacing={2}>
            <Box
                sx={{
                    display: 'flex',
                    height: '180px',
                    overflow: 'auto'
                }}
            >
            <FormControl>
            <FormLabel id="fileselect-rgroup-label">Select file to be extracted</FormLabel>
            <RadioGroup
                aria-labelledby="fileselect-rgroup-label"
                name="fileselect-rgroup"
                onChange={handleFileSourceChange}
                sx={{
                    pl: 2
                }}
            >
                {fileslist && fileslist.map((fs: FileSource) => (
                    // Filter only with gvf files
                    fs.sourcename.includes('.gvf') && (
                    <FormControlLabel value={fs.id} control={<Radio />} label={fs.sourcename} />
                    )
                ))}
                
                
            </RadioGroup>
            </FormControl>
            </Box>
            <Box
                justifyContent='flex-end'
                sx={{
                    display: 'flex',
                    minHeight: '38px',
                    pt: 1,
                    pl: 0,
                    pr: 0,
                    borderTop: '1px solid #212121'
                }}
            >
                <Button
                    variant='contained'
                    disabled={filesrc ? false:true}
                    onClick={handleSubmit}
                >
                    Extract Dataset
                </Button>
            </Box>

        </Stack>
        </>
    )
}