import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { addInstitutionAPI, fetchInstitutionAPI } from '../../../../reducers/func/dataManagementFunc'
import { HealthCareInstitution } from '../../../../types'


interface INewInstitution {
    onSaveResult: (success: boolean, msg: string) => void
}

export const AddInstitutionForm: React.FC<INewInstitution> = (props: INewInstitution) => {
    const form = useForm()
    const { onSaveResult } = props
    //const [institutiontype, setInstitutionType] = React.useState('')

    const onNewInstitutionSubmitted = async (data: any) => {
        const postdata: HealthCareInstitution = {
            type: data.institutiontype,
            name: data.institutionname
        }
        try {
            await addInstitutionAPI(postdata)
            onSaveResult(true, "")
        } catch(e) {
            onSaveResult(false, "Error saving data to the backend.")
        }
    } 

    return (
        <>
        <form>
        <Box
            sx={{
                display: 'flex',
                height: '200px',
                p: 1,
                overflow: 'auto'
            }}
        >
            <Stack spacing={1}>
            <Controller
                name="institutiontype"
                control={form.control}
                rules={{ 
                    required: {
                        value: true,
                        message: "Healthcare Institute type is required."
                    }
                }}
                render={({ field, fieldState, formState }) => 
                    
                    <TextField
                    {...field}
                    variant="standard"
                    label="Institution Type"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    error={fieldState.error ? true:false}
                    helperText={fieldState.error && fieldState.error.message}
                    select
                    sx={{ width: 200 }}
                    >
                            <MenuItem value={0}>Hospital</MenuItem>
                            <MenuItem value={1}>Clinic</MenuItem>
                            <MenuItem value={2}>Medical center</MenuItem>
                    </TextField>
                }
            />
            <Controller
                name='institutionname'
                control={form.control}
                rules={{
                    required: {
                        value: true,
                        message: "Please enter institution name."
                    }
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Institution Name"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    error={fieldState.error ? true:false}
                    helperText={fieldState.error && fieldState.error.message}
                    sx={{ width: 200 }}
                />}
            />  
            </Stack>
        </Box>
        <Box
            justifyContent='flex-end'
            sx={{
                display: 'flex',
                minHeight: '38px',
                pt: 1,
                pl: 2,
                pr: 2,
                borderTop: '1px solid #212121'
            }}
        >
            <Button
                variant='contained'
                onClick={form.handleSubmit(onNewInstitutionSubmitted)}
            >
                Save New Institution
            </Button>
        </Box>
        </form>
        </>
    )
} 