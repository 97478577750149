import * as React from 'react'
import { Image, Layer, Stage } from 'react-konva'
import { CustomFab } from '../../controls/CustomControls'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface ILesionLabelImageView {
    image: any
    canvaswidth: number
    canvasheight: number
}
export const LesionLabelImageView: React.FC<ILesionLabelImageView> = (props: ILesionLabelImageView) => {
    const { image, canvaswidth, canvasheight } = props
    const [selFrameImage, setSelFrameImage] = React.useState<any>(null)
    const imageRef = React.createRef<any>()
    const [zoomLevel, setZoomLevel] = React.useState<number>(1)
    const [imageStartPos, setImageStartPos] = React.useState<number[]>([0, 0])
    const [isDragging, setIsDragging] = React.useState<boolean>(false)

    const zoomIn = () => {
        setZoomLevel(zoomLevel + 0.1)
        const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) * 1.1
        const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) * 1.1
        setImageStartPos([new_x, new_y])
    }

    const zoomOut = () => {
        setZoomLevel(zoomLevel - 0.1)
        const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) / 1.1
        const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) / 1.1
        setImageStartPos([new_x, new_y])
    }

    const dragStart = () => {
        setIsDragging(true)
    }

    const dragEnd = (event: any) => {
        setImageStartPos([event.target.x(), event.target.y()])
        setIsDragging(false)
    }

    const resetImageView = () => {
        setZoomLevel(1)
        const x = (canvaswidth - selFrameImage.width) / 2
        const y = (canvasheight - selFrameImage.height) / 2
        setImageStartPos([x, y])
    }

    React.useEffect(() => {
        if (image) {
            let frameimage = new window.Image()
            frameimage.src = image || '' // Add a default value of an empty string if item.imagedata is undefined
            frameimage.crossOrigin = 'anonymous'
            frameimage.onload = () => {
                setSelFrameImage(frameimage)
                setZoomLevel(1)
                const x = (canvaswidth - frameimage.width) / 2
                const y = (canvasheight - frameimage.height) / 2
                setImageStartPos([x, y])
            }
        }
    }, [image])

    return (
        <>
            <Stage width={canvaswidth} height={canvasheight}>
                <Layer>
                    <Image
                        image={selFrameImage}
                        x={imageStartPos[0]}
                        y={imageStartPos[1]}
                        scaleX={zoomLevel}
                        scaleY={zoomLevel}
                        ref={imageRef}
                        draggable={true}
                        onDragStart={dragStart}
                        onDragEnd={dragEnd}
                    />
                </Layer>

            </Stage>
            <CustomFab
                aria-label="zoomin"
                onClick={() => { zoomIn() }}
                disabled={zoomLevel < 2 ? false : true}
                sx={{
                    position: 'absolute' as 'absolute',
                    top: 96
                }}>
                <ZoomInIcon fontSize="large" />
            </CustomFab>
            <CustomFab
                aria-label="zoomout"
                onClick={() => { zoomOut() }}
                disabled={zoomLevel > 1 ? false : true}
                sx={{
                    position: 'absolute' as 'absolute',
                    top: 144
                }}>
                <ZoomOutIcon fontSize="large" />
            </CustomFab>
            <CustomFab
                aria-label="reset"
                onClick={resetImageView}
                sx={{
                    position: 'absolute' as 'absolute',
                    top: 192
                }}>
                <RestartAltIcon fontSize="large" />
            </CustomFab>
        </>
    )
}