import { Autocomplete, Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { addPatientAPI, fetchInstitutionAPI, fetchPatientsAPI } from '../../../../reducers/func/dataManagementFunc'
import { HealthCareInstitution, Patient } from '../../../../types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

interface INewPatient {
    institution: HealthCareInstitution | null
    onSaveResult: (success: boolean, msg: string) => void
}

export const AddPatientForm: React.FC<INewPatient> = (props: INewPatient) => {
    const form = useForm()
    const { institution, onSaveResult } = props
    const [institutions, setInstitutions] = React.useState<HealthCareInstitution[]>([])
    const [birthdate, setBirthDate] = React.useState<Date | null>(null)
    const onNewPatientSubmitted = async (data: any) => {
        const postdata: Patient = {
            institution: data.institution,
            refno: data.refno,
            // firstname: data.firstname,
            // lastname: data.lastname,
            // birthdate: data.birthdate,
            // weight: data.weight,
            // height: data.height
        }
        try {
            await addPatientAPI(postdata)
            onSaveResult(true, "")
        } catch(e) {
            onSaveResult(false, "Error saving data to the backend.")
        }
    } 

    React.useEffect(() => {
        if (institutions.length === 0) {
            fetchInstitutionAPI()
                .then((response) => {
                    setInstitutions(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching institutions: ", error)
                })
        }
        
    }, [institutions])

    return (
        <>
        <form>
        <Box
            sx={{
                display: 'flex',
                height: '350px',
                p: 1,
                overflow: 'auto'
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={1}>
            <Controller
                name="institution"
                control={form.control}
                rules={{ 
                    required: {
                        value: true,
                        message: "Healthcare Institute is required."
                    }
                }}
                defaultValue={institution}
                render={({ field, fieldState, formState }) => 
                <Autocomplete
                {...field}
                disablePortal
                options={institutions}
                sx={{ width: 200 }}
                onChange={(event: any, newValue: any | null) => {
                    field.onChange(newValue.name)
                }}
                getOptionLabel={(option: any) => option.name}
        
                renderInput={(params: any) => 
                    <TextField {...params} 
                        label="Healthcare Institute (required)" 
                        variant="standard" 
                        color="secondary"
                        error={fieldState.error ? true:false}
                        helperText={fieldState.error && fieldState.error.message}
                                    
                    />}
                
                />
                    
                }
            />
            <Controller
                name='refno'
                control={form.control}
                rules={{
                    required: {
                        value: true,
                        message: "Please enter patient reference number."
                    }
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Reference No. (required)"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    error={fieldState.error ? true:false}
                    helperText={fieldState.error && fieldState.error.message}
                    sx={{ width: 200 }}
                />}
            />  
            {/* <Controller
                name='firstname'
                control={form.control}
                rules={{
                    required: false
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Firstname (optional)"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    sx={{ width: 200 }}
                />}
            />
            <Controller
                name='lastname'
                control={form.control}
                rules={{
                    required: false
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Lastname (optional)"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    sx={{ width: 200 }}
                />}
            />
            <Controller
                name='birthdate'
                control={form.control}
                rules={{
                    required: false
                }}
                render={({ field, fieldState, formState }) => 
                        
                <DatePicker
                    {...field}
                    label="Birth Date (optional)"
                    inputFormat="yyyy-MM-dd"
                    value={birthdate}
                    onChange={(newValue) => {
                        field.onChange(newValue)
                        setBirthDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} variant="standard" sx={{ width: 200 }} />}
                />}
            />
            <Controller
                name='weight'
                control={form.control}
                rules={{
                    required: false
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Weight (optional)"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    sx={{ width: 200 }}
                />}
            />
            <Controller
                name='height'
                control={form.control}
                rules={{
                    required: false
                }}
                render={({ field, fieldState, formState }) => 
                        
                <TextField
                    {...field}
                    variant="standard"
                    label="Height (optional)"
                    color="secondary"
                    onChange={(event) => {
                        field.onChange(event.target.value)
                        
                    }}
                    sx={{ width: 200 }}
                />}
            /> */}
            </Stack>
            </LocalizationProvider>
        </Box>
        <Box
            justifyContent='flex-end'
            sx={{
                display: 'flex',
                minHeight: '38px',
                pt: 1,
                pl: 2,
                pr: 2,
                borderTop: '1px solid #212121'
            }}
        >
            <Button
                variant='contained'
                onClick={form.handleSubmit(onNewPatientSubmitted)}
            >
                Save New Patient
            </Button>
        </Box>
        </form>
        </>
    )
} 