import { FormControl, FormControlLabel, FormLabel, IconButton, ImageList, ImageListItem, ImageListItemBar, ListItemIcon, ListItemText, Menu, MenuItem, Radio, RadioGroup, Slider, Typography } from '@mui/material'
import * as React from 'react'
import { GIOrganLabel, GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, LesionLabel, LesionLabelViewset, instanceofGIOrganLabel, instanceofImageDataframe, instanceofLesionLabel } from '../../../../types'
import MenuIcon from '@mui/icons-material/Menu';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { fetchGIOrganLabelAPI, fetchImageDataByTSAPI, fetchLesionLabelAPI } from '../../../../reducers/func/dataManagementFunc';
import TaskIcon from '@mui/icons-material/Task';

interface IImageDataframeList {
    datatype: string
    dataid: string
    viewset: ImageDataframeViewset | GIOrganLabelViewset | LesionLabelViewset | null
    start: number
    stop: number
    showLabel: Boolean
    multiSelection: Boolean
    onViewsetUpdated: (viewset: ImageDataframeViewset | GIOrganLabelViewset | LesionLabelViewset) => void
    onItemSelected: (item: ImageDataframe) => void
    // onEditLabel?: (item: ImageDataframe, label: number) => void
}

function viewrangevaluetext(value: number) {
    return `${value} ms`
}

export const ImageDataframeList: React.FC<IImageDataframeList> = (props: IImageDataframeList) => {
    const { datatype, dataid, viewset, start, stop, showLabel, multiSelection,
        onViewsetUpdated, onItemSelected } = props
    const [viewrange, setViewRange] = React.useState<number[]>([])
    // const [start, setStart] = React.useState<number>(0)
    // const [stop, setStop] = React.useState<number>(0)
    const [viewlabel, setViewLabel] = React.useState<number | null>(-1)
    const [actionmenuanchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openActionMenu = Boolean(actionmenuanchorEl)

    const handleViewRangeChange = (event: Event, newValue: number | number[]) => {
        const range = newValue as number[]
        setViewRange(range)
    }
    const handleViewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setViewLabel((event.target as HTMLInputElement).value as unknown as number)
    }
    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setActionMenuAnchorEl(event.currentTarget);
    }

    // const handleUpdateMark = (ds: string, organ: string, sample: number) => {
    //     updateMarkAPI(ds, organ, sample)
    //         .then((response) => {
    //             setActionMenuAnchorEl(null)
    //             onUpdate()
    //         })
    //         .catch((error) => {
    //             console.log("Error updating mark: ", error)
    //         })
    // }
    // const handleEditLabel = (label: number) => {
    //     if (onEditLabel) {
    //         onEditLabel(item, label)
    //     }
    // }

    const handleItemSelected = function (d: ImageDataframe | GIOrganLabel | LesionLabel, vs: ImageDataframeViewset | GIOrganLabelViewset | LesionLabelViewset | null) {
        if (vs === null) {
            return
        }
        // 
        if (instanceofImageDataframe(d) && datatype === 'dataframe') {
            onItemSelected(d)
        } else if ((instanceofGIOrganLabel(d) || instanceofLesionLabel(d)) && d.dataframe) {
            onItemSelected(d.dataframe)
        }

    }

    const handleDataListScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 420) {
            if (viewset?.page as number + 1 <= (viewset?.totalpages as number)) {
                handleFetchData(viewset?.page as number + 1)
            }
        }
    }

    const handleFetchData = (page: number) => {
        if (dataid) {
            if (datatype === 'organ') {
                fetchGIOrganLabelAPI(dataid, viewlabel, page, 12)
                    .then((response) => {
                        if (page > 1 && viewset !== null) {
                            const data = viewset?.data?.concat(response.data.results) as any
                            onViewsetUpdated({
                                ...viewset,
                                data: data,
                                page: page,
                                count: response.data.count,
                            })
                            // prepareHeatmap(viewset?.data?.concat(response.data.results) as GIOrganLabel[], page)

                        } else {
                            onViewsetUpdated({
                                //...giorganlabelviewset,
                                data: response.data.results,
                                totalpages: response.data.total_pages,
                                page: 1,
                                count: response.data.count,
                                // selframe: response.data.results[0].dataframe,
                                selitem: response.data.results[0],
                                selitems: [],
                                pointer: 0
                            })
                            // prepareHeatmap(response.data.results, 1)
                        }

                    })
                    .catch((error) => {
                        console.log("Error fetching organ labels: ", error)
                    })
            } else if (datatype === 'lesion') {
                fetchLesionLabelAPI(dataid, viewlabel, page, 12)
                    .then((response) => {
                        if (page > 1 && viewset !== null) {
                            const data = viewset?.data?.concat(response.data.results) as any
                            onViewsetUpdated({
                                ...viewset,
                                data: data,
                                page: page,
                                count: response.data.count,
                            })
                            // prepareHeatmap(viewset?.data?.concat(response.data.results) as GIOrganLabel[], page)

                        } else {
                            onViewsetUpdated({
                                //...giorganlabelviewset,
                                data: response.data.results,
                                totalpages: response.data.total_pages,
                                page: 1,
                                count: response.data.count,
                                // selframe: response.data.results[0].dataframe,
                                selitem: response.data.results[0],
                                selitems: [],
                                pointer: 0
                            })
                            // prepareHeatmap(response.data.results, 1)
                        }
                    })
                    .catch((error) => {
                        console.log("Error fetching lesion labels: ", error)
                    })
            } else {
                fetchImageDataByTSAPI(dataid, (viewrange.at(0) as number), (viewrange.at(1) as number), page)
                    .then((response) => {
                        if (page > 1 && viewset !== null) {
                            const data = viewset?.data?.concat(response.data.results) as any
                            onViewsetUpdated({
                                ...viewset,
                                data: data,
                                page: page,
                                count: response.data.count,
                            })
                        } else {
                            onViewsetUpdated({
                                data: response.data.results,
                                totalpages: response.data.total_pages,
                                page: 1,
                                count: response.data.count,
                                selitem: response.data.results[0],
                                selitems: [],
                                pointer: 0
                            })
                        }
                    })
                    .catch((error) => {
                        console.log("Error fetching dataframes: ", error)
                    })
            }
        }
    }

    React.useEffect(() => {
        if (viewset === null && datatype === 'dataframe') {
            setViewRange([start, stop])
        }
    }, [datatype])

    React.useEffect(() => {
        if (viewrange.length > 0) {
            handleFetchData(1)
        }
    }, [viewrange])

    React.useEffect(() => {
        handleFetchData(1)
    }, [viewlabel])

    React.useEffect(() => {
        if (viewset === null) {
            handleFetchData(1)
        }
    }, [viewset])

    return (
        <>

            {datatype === 'organ' ? (
                <FormControl>
                    <FormLabel id="viewlabel-radio-buttons-group">Filter data by organ</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="viewlabel-radio-buttons-group"
                        name="viewlabel-radio-buttons-group"
                        value={viewlabel}
                        onChange={handleViewLabelChange}
                    >
                        <FormControlLabel value={null} control={<Radio />} label="All" />
                        <FormControlLabel value={0} control={<Radio />} label="Esophagus" />
                        <FormControlLabel value={1} control={<Radio />} label="Stomach" />
                        <FormControlLabel value={2} control={<Radio />} label="Small Bowel" />
                        <FormControlLabel value={3} control={<Radio />} label="Colon" />
                    </RadioGroup>
                </FormControl>
            ) : datatype === 'lesion' ? (
                <FormControl>
                    <FormLabel id="viewlabel-radio-buttons-group">Filter data by organ</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="viewlabel-radio-buttons-group"
                        name="viewlabel-radio-buttons-group"
                        value={viewlabel}
                        onChange={handleViewLabelChange}
                    >
                        <FormControlLabel value={-1} control={<Radio />} label="All" />
                        <FormControlLabel value={0} control={<Radio />} label="Normal" />
                        <FormControlLabel value={1} control={<Radio />} label="Lesion" />
                    </RadioGroup>
                </FormControl>
            ) : (
                <>
                    <Typography id="timeframw-slider" gutterBottom>
                        Search data by timeframe
                    </Typography>
                    <Slider
                        getAriaLabel={() => 'Dataframes view range'}
                        value={viewrange}
                        onChange={handleViewRangeChange}
                        valueLabelDisplay="off"
                        getAriaValueText={viewrangevaluetext}
                        step={1}
                        min={start}
                        max={stop}
                        marks={
                            [
                                {
                                    value: start,
                                    label: `${start} ms`
                                },
                                {
                                    value: Math.ceil((stop - start) / 4),
                                    label: `${Math.ceil((stop - start) / 4)} ms`
                                },
                                {
                                    value: Math.ceil((stop - start) / 2),
                                    label: `${Math.ceil((stop - start) / 2)} ms`
                                },
                                {
                                    value: Math.ceil((stop - start) * 3 / 4),
                                    label: `${Math.ceil((stop - start) * 3 / 4)} ms`
                                },
                                {
                                    value: stop,
                                    label: `${stop} ms`
                                }
                            ]}

                    />
                </>
            )}

            <ImageList
                variant="quilted"
                onScroll={handleDataListScrollEnd}
                sx={{ height: 420 }} cols={3} rowHeight={120}>
                <>
                    {viewset?.data?.map((item: GIOrganLabel | LesionLabel | ImageDataframe) => (
                        <ImageListItem key={(instanceofImageDataframe(item) && datatype === 'dataframe') ? item.id :
                            (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) ? item.dataframe?.id : ``}
                            onClick={() => { handleItemSelected(item, viewset) }}
                            sx={{
                                borderBlockColor: 'primary.main',
                            }}>
                            {(instanceofImageDataframe(item) && datatype === 'dataframe') ? (
                                <img
                                    src={item.thumbdata ? `${item.thumbdata}` : `${item.imagedata}`}
                                    loading="lazy"
                                />
                            ) : (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) && (
                                <img
                                    src={item.dataframe?.thumbdata ? `${item.dataframe?.thumbdata}` : `${item.dataframe?.imagedata}`}
                                    loading="lazy"
                                />
                            )}
                            <ImageListItemBar
                                position='top'
                                actionIcon={
                                    (viewset.selitem?.id === item.id) ? (
                                        <CheckCircleOutlineIcon sx={{ color: "white" }} />
                                    ) :
                                        (viewset.selitems?.findIndex((i) => i.id === item.id) !== -1) && (
                                            <CheckCircleOutlineIcon sx={{ color: "green" }} />
                                        )

                                }
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                            />
                            <ImageListItemBar
                                title={showLabel && (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) && datatype !== 'dataframe' ? `${item.labelname}` : `${instanceofImageDataframe(item) ? item.sample : item.dataframe?.sample}`}
                                subtitle={<span>{(instanceofImageDataframe(item) && datatype === 'dataframe') ? item.hms :
                                    (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) ? item.dataframe?.hms : ``}</span>}
                                actionIcon={
                                    // <IconButton
                                    //     onClick={handleActionMenuClick}
                                    //     size="small"
                                    //     sx={{ color: "white" }}
                                    //     aria-controls={openActionMenu ? 'action-menu' : undefined}
                                    //     aria-haspopup="true"
                                    //     aria-expanded={openActionMenu ? 'true' : undefined}
                                    // >
                                    //     <MenuIcon />
                                    // </IconButton>
                                    instanceofImageDataframe(item) && datatype === 'dataframe' ? (<></>) :
                                        (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) && item.mark_for_export && (
                                            <TaskIcon sx={{ color: "green" }} />
                                        )
                                }
                                sx={{
                                    background:
                                        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                            />
                        </ImageListItem>
                        // <>
                        //     {(instanceofImageDataframe(item) && datatype === 'dataframe') ? `df:${item.id},` :
                        //         (instanceofGIOrganLabel(item) || instanceofLesionLabel(item)) ?
                        //             `labeldf:${item.dataframe?.id},` : ``}
                        // </>
                    ))}
                </>
            </ImageList>
        </>
    )
}