
export const authorize_url = 'https://staging-api.gutdx.com/o/authorize';
export const client_id = 'xQg3NIepvV4sJERyitJsAWQ2uFbngy3vFBMm2LVh';
export const client_secret = 'Jy7ikw7BROzEPoyp19QKlDqgjAxzsD3ZxgfuouGNEEgytux7XFlbCfxuEVYMCsxezCa1osQPIWN9A7U5XXi3cA7SacRzKuYf63Yt0kDpA2RkHxbcksEEEprYmlQt6WubJy7ikw7BROzEPoyp19QKlDqgjAxzsD3ZxgfuouGNEEgytux7XFlbCfxuEVYMCsxezCa1osQPIWN9A7U5XXi3cA7SacRzKuYf63Yt0kDpA2RkHxbcksEEEprYmlQt6Wub';
export const redirect_uri = encodeURIComponent(`${window.location.origin}/redirectafterlogin/`);
export const rest_endpoint = 'https://staging-api.gutdx.com/';
export const public_url = 'https://staging-analytics.gutdx.com'

// local development settings
// export const authorize_url = 'http://localhost:10900/o/authorize';
// export const client_id = 'njDKDXo3300JtMVzWWzIuOp0JF3iQjw0L8nGOARy';
// export const client_secret = '85KWPMLNLFfjMoWCjATXHtNIJOQ2n9b6HwIWdIX9m0ayZCGjzdNQ3ecHvr9cLF6IxxHrlbi1kVXx1j10cOzEBVb7wW6CwunAGUFaPU7xcTMR2hadHM4sLZj2jfS1bKaS';
// export const redirect_uri = encodeURIComponent(`${window.location.origin}/redirectafterlogin/`);
// export const rest_endpoint = 'http://localhost:10900/';
// export const public_url = 'http://localhost:3001'
